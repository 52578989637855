import React, { useEffect, useState } from 'react'
import style from 'css/user/FilterOptions.module.css';
import selected from 'css/user/SelectedDiv.module.css';
import {filterSongs} from 'actions';
import {useSelector, useDispatch} from 'react-redux';
import {getFilterValues} from '../../../getFilters';


export default function FilterDropdown({ filter }) {
    const [options, setOptions] = useState([]);
    let optionsElems;

    const dispatch = useDispatch();
    let {[filter]: selectedFiltersFromStore} = useSelector(state => state.songsReducer.filters);
    selectedFiltersFromStore = selectedFiltersFromStore || [];
    
    useEffect(() => {
        (async () => {
            const options = await getFilterValues(filter);
            setOptions(options);
        })();
    }, [])

    /*
    We will need some logic to determine if each filter needs to be checked or not.
    Once redux is set up, we will need to get the filters object from the store. For
    each filter, we will have to loop through the selected filters and determine whether 
    the current filter is selected already.

    e.g.:
    const filters = state => state.filters.
    Then inside input tag:
    <input checked={filters[filter].some(f === v)}>

    basically, check if any of the filters that are selected under this filter name are equal to
    the current value.
    */

    function isSelected(value) {
        return selectedFiltersFromStore.some(sf => sf === value);
    }

    const handleInputChange = e => {
        console.log(e.target.name, filter);
        const elems = Array.from(document.querySelectorAll(`.${filter}`));

        const selectedFilters = {[filter]: elems.filter(elem => elem.checked === true).map(elem => elem.name)};
        // console.log(selectedFilters);
        dispatch(filterSongs(selectedFilters))
    }

    // turn all options into actual selectable divs
    switch (filter) {
        case 'difficulty':
            optionsElems = options.map((v, i) => {
                return (
                    <div key={i} className={selected.container}>
                        <input type="checkbox" 
                        checked={isSelected(v)} 
                        onChange={handleInputChange}  
                        name={v} 
                        className={`${selected.checkbox} ${filter}`} 
                        />
                        <span></span>
                        <div className={`${v} ${style.option}`}></div>
                    </div>
                )
            })
            break;
        default:
            optionsElems = options.map((v, i) => {
                return (
                    <div key={i} className={selected.container}>
                        <input type="checkbox" 
                        checked={isSelected(v)}
                        onChange={handleInputChange} 
                        name={v} 
                        className={`${selected.checkbox} ${filter}`} 
                        />
                        <span></span>
                        <div className={`${style.option}`}>{v}</div>
                    </div>
                )
            });
            break;
        }

    return (
        <div className={style.dropdownContainer} >
            {optionsElems}
        </div>
    )
}
