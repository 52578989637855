import React, { useState, useEffect, useRef } from 'react'
import style from 'css/user/FeaturedChords.module.css';
import FtrChrdsMainDisp from './FtrChrdsMainDisp';
import FtrChrdsMiniDisp from './FtrChrdsMiniDisp';
import executeFetch from 'executeFetch';
import generateAlbumImageUrl from 'generateAlbumImageUrl';

/* eslint-disable */

export default function FeaturedChords() {

    const [featuredSongs, setFeaturedSongs] = useState([]);
    const [mainImgIndex, setMainImgIndex] = useState(1); // 1 is the middle song of three
    const mainImgIndexRef = useRef(null);
    mainImgIndexRef.current = mainImgIndex;
    const NUM_FEATURED_SONGS = 3;
    let interval;
    const intervalRef = useRef(null);
    // intervalRef.current = interval;

    useEffect(() => {
        (async () => {
            const featuredSongs = await executeFetch.get('/retrieve-songs/c/featured?&limit=3');
            // console.log('featuredSongs', featuredSongs);
            featuredSongs.forEach(song => song.imageUrl = generateAlbumImageUrl(song));
            setFeaturedSongs(featuredSongs);
        })();
    }, [])

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if(mainImgIndexRef.current === 2) {
                setMainImgIndex(0);
            } else {
                setMainImgIndex(mainImgIndexRef.current + 1);
            }
        }, 2000)
    }, [])

    const miniDisplays = featuredSongs.map((s, i) => {
        return (
            <FtrChrdsMiniDisp key={i} song={s} index={i} setMainImg={setMainImgIndex} active={i === mainImgIndex} mainImageIntervalRef={intervalRef} />
        )
    });
    // miniDisplays[mainImgIndex].props.active = true;
    // console.log('DISPLAYS', miniDisplays);


    return (
        <div id={style.featuredChords}>
            <h1>Featured Chords</h1>
            <div id={style.featuredChordsContentDiv}>
                <FtrChrdsMainDisp
                    mainImgIndex={mainImgIndex}
                    songs={featuredSongs}
                    setMainImgIndex={setMainImgIndex} 
                    mainImageIntervalRef={intervalRef} />

                {[...Array(NUM_FEATURED_SONGS)].map((_, i) => {
                    return (
                        // eslint-disable-next-line
                        <line key={i} className={`${style.ftrChordsConnLine} ${i === mainImgIndex ? style.connLineActive : ''}`} />
                    )
                })}

                <div id={style.miniDisplaysContainer}>
                    {miniDisplays}
                </div>

            </div>
        </div>
    )
}
