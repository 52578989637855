import React from 'react'
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Nav from './Nav';
import Home from './Home';
import AllChords from './AllChords';
import SongSingle from './SongChordPage';
import ModalContent from './ModalContent'
// import SubscribeButton from './SubscribeButton'
import Footer from './Footer';
import About from './About';
import NewChords from './NewChords';
import FeaturedChords from './FeaturedChords';
// import UserLogin from './UserLogin/UserLogin';
import SearchResults from './SearchResults';
// import { useHistory } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';

export default function LayoutUser({ user, setUser }) {

    // const [userLoggedIn, setUserLoggedIn] = useState(true);
    // const history = useHistory();
    const [popup, setPopup] = useState(null);
    const [modalShowing, setModalShowing] = useState(false);

    const modalContent = modalShowing ? <ModalContent popup={popup} setModalShowing={setModalShowing} /> : null;





    return (

            <>
                <Nav />

                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/all-chords">
                        <AllChords />
                    </Route>
                    <Route path="/songs/:slug">
                        <SongSingle />
                    </Route>
                    <Route path="/new-chords">
                        <NewChords />
                    </Route>
                    <Route path="/featured-chords">
                        <FeaturedChords />
                    </Route>
                    <Route path="/search">
                        <SearchResults />
                    </Route>
                </Switch>

                <Footer setPopup={setPopup} setModalShowing={setModalShowing} />
                {modalContent}
            </>
        )
}
