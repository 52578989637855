import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function CapoPlacementInput({ allSongData, setAllSongData }) {
    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <label htmlFor="capoPlacement">Capo Placement:</label>
                    <input
                        className='focusable'
                        name="capoPlacement"
                        value={allSongData.capoPlacement}
                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })} />
                </div>
            }
        </>
    )
}
