import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function StrummingPatternInput({ allSongData, setAllSongData }) {
    const fullStrummingPattern = ['D', 'U', 'D', 'U', 'D', 'U', 'D', 'U'];

    // we initialize the array to have 8 slots containing null, which we will need later
    const initialStrumArrayValues = Array(fullStrummingPattern.length).fill(null);
    const {strummingPattern = initialStrumArrayValues} = allSongData;


    function updateStrummingPattern(e) {
        const index = +e.target.id; // retrieve index (i wasn't working for some reason)
        const newArray = [...strummingPattern]; // create new array
        newArray[index] = e.target.checked ? e.target.value : null; 
        setAllSongData({...allSongData, strummingPattern: newArray}); 
    }

    return (
        <>
            {allSongData  &&
                <div className={style.inputSection}>
                    <label>Strumming Pattern:</label>
                    <div className={style.downUpContainer}>
                        {fullStrummingPattern.map((strum, i) => {
                            return (
                                <div className={`flex ${style.downUp}`} key={i}>

                                    <label htmlFor={i}>
                                        <input
                                            className='focusable'
                                            type="checkbox"
                                            id={i}
                                            name="strummingPattern"
                                            value={strum}
                                            checked={strum === strummingPattern[i]}
                                            onChange={updateStrummingPattern}
                                        />
                                        {strum}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}
