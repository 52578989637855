import React from 'react'
import style from 'css/user/FilterDropdownMobile.module.css';
import FilterDropdownMobileOption from './FilterDropdownMobileOption';

import {filterCategories} from '../../../constants';


export default function FilterDropdownMobile() {
    const elems = filterCategories.map((f, i) => <FilterDropdownMobileOption key={i} filter={f} />);

    return (
        <div className={style.container}>
            {elems}
        </div>
    )
}
