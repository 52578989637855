import React, { useState, useEffect } from 'react'
import style from 'css/admin/SubmitSong.module.css';
import executeFetch from 'executeFetch';
import { generateSlug } from './convertToDBformat';

export default function SongNameInput({ allSongData, setAllSongData }) {
    const [slugs, setSlugs] = useState([])

    useEffect(() => {
        (async () => {
            const slugs = await executeFetch.get('/search/slugs')
            // console.log(slugs);
            setSlugs(slugs);
        })();
    }, [])

    function handleNameUpdate(e) {
        const slug = generateSlug(e.target.value, slugs, allSongData.ID) || ''
        setAllSongData({ ...allSongData, [e.target.name]: e.target.value, slug })
    }

    return (
        <>
            {allSongData &&
                < div className={style.inputSection} >
                    <input className='focusable'
                        name="songName"
                        value={allSongData.songName}
                        onChange={handleNameUpdate}
                        placeholder="Enter song name"
                        required autoFocus />
                </div >
            }
        </>
    )
}