import { useState } from 'react';

const useForm = (initialFormValues) => {
    const [formValues, setFormValues] = useState(initialFormValues);

    // We are returning a 3rd value - a function that allows us to directly manage the form values.
    // This is useful for when we are retrieving drafts from the database.

    return [
        formValues,
        e => setFormValues({ ...formValues, [e.target.name]: e.target.value }),
        setFormValues
    ];
};

export default useForm;