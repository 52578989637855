import React, { useState, useEffect, useRef } from 'react'
import style from 'css/user/AutoScroller.module.css';

export default function AutoScroller({ scrolling, toggleScrolling, bpl, tempo }) {
    
    const [beatsPerMin, setBeatsPerMin] = useState(tempo);
    let millisecondsIncrement = 125;
    const ONE_MIN_MS = 60000;
    const PIXELS_INCREMENT = 1;
    const highestScrollValue = 250;
    const lowestScrollValue = 50;
    const LEFT_CLICK_SPEED_INCREMENT = 1;
    const RIGHT_CLICK_SPEED_INCREMENT = 10;
    const intervalRef = useRef();
    const scrollButtonText = scrolling ? 'Stop' : 'Start';
    
    // Active = autoscroll should be showing. We are implementing a delay that the autoscroll should disappear after inactivity
    const [activeState, setActiveState] = useState(true);
    const activeClass = activeState ? '' : 'inactive';


    // This comment is being commented out as I'm not sure if it still applies. /* PLEASE ORGANIZE THIS FILE AND COMMENT AS APPROPRIATE */

    // Set timeout for the autoscroll to become inactive and disappear
    useEffect(() => {
        if(activeState) {
        setTimeout(() => {
            setActiveState(false)
        }, 5000);
    }
    }, [activeState])

    // Add listeners to restore visibility to autoscroll on mousemove or touch
    useEffect(() => {
        function restoreVisibility() {
            setActiveState(true);
        }

        window.addEventListener('mousemove', restoreVisibility);
        window.addEventListener('touchstart', restoreVisibility);

        return () => {
            window.removeEventListener('mousemove', restoreVisibility);
            window.removeEventListener('touchstart', restoreVisibility);
        }
    }, [])

    // Handle scroll speed
    useEffect(() => {
        let lineHeight = window.getComputedStyle(document.querySelector('#chordsLyrics')).getPropertyValue('line-height');
        lineHeight = +lineHeight.replace('px', '');

        const singleBeatAsPixels = (lineHeight * 2) / +bpl;
        // console.log('singleBeatAsPixels', singleBeatAsPixels);
        const totalPixelsPerMin = singleBeatAsPixels * beatsPerMin;
        // console.log('totalPixelsPerMin', totalPixelsPerMin);
        millisecondsIncrement = Math.round(ONE_MIN_MS / totalPixelsPerMin);
        // console.log('msIncrement', msIncrement);
    }, [scrolling, beatsPerMin])

    function handleSpeedChange(value) {
        if (beatsPerMin + value <= highestScrollValue && beatsPerMin + value >= lowestScrollValue) {
            setBeatsPerMin(beatsPerMin + value);
        }
    }

    function handleScrollToggle() {
        toggleScrolling(!scrolling);
    }

    useEffect(() => {
        // stop the scrolling, whether the speed changed or the start/stop button was toggled.
        stop();

        // if scrolling === true, the speed changed. Therefore, scroll and start a new interval
        if (scrolling) {
            // commenceScrolling();
            scroll();
            intervalRef.current = setInterval(scroll, millisecondsIncrement);
        }

        // Stop scrolling once this component is unmounted i.e. user navigates away
        return () => {
            stop();
        }
    }, [scrolling, beatsPerMin])

    function scroll() {
        window.scrollBy(0, PIXELS_INCREMENT);

        // Stop scrolling once we reach the bottom
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            
            // Allow some time before turning off scrolling mode
            setTimeout(() => {
            handleScrollToggle(false);
            // Since there's a different layout for scrolling mode, scroll us all the way to the bottom once stopped.
            window.scrollTo(0, document.body.offsetHeight); // OffsetHeight might actually be overshooting what we want, but who really cares...

        }, 5000)
        }
    }

    function stop() {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    /* Note that in this component, onContextMenu event is used to track right clicks. */
    
    return (
        <div className={`${style.autoScrollerContainer} ${style[activeClass]}`}>
            <div className={style.contentContainer}>

                <div className={style.speedLabel}>BPM:</div>

                <div className={style.speedControlContainer}>
                    <div 
                    onClick={() => handleSpeedChange(-LEFT_CLICK_SPEED_INCREMENT)}
                    onContextMenu={e => {
                        e.preventDefault();
                        handleSpeedChange(-RIGHT_CLICK_SPEED_INCREMENT);
                    }}
                    className={`${style.speedButton} ${style.leftSpeedButton}`}
                    >-</div>
                    {/* <div className={style.scrollNumber} onWheel={e => handleSpeedChange(-1 * Math.sign(e.deltaY))}>{beatsPerMin}</div> */}
                    <div 
                    className={style.scrollNumber}
                    >{beatsPerMin}</div>
                    <div 
                    onClick={() => handleSpeedChange(LEFT_CLICK_SPEED_INCREMENT)} 
                    onContextMenu={e => {
                        e.preventDefault();
                        handleSpeedChange(RIGHT_CLICK_SPEED_INCREMENT);
                    }}
                    className={`${style.speedButton} ${style.rightSpeedButton}`}
                    >+</div>
                </div>

                <button className={style.scrollButton} onClick={handleScrollToggle}>{scrollButtonText}</button>
            </div>
        </div>
    )
}
