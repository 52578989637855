import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function BeatsPerLineInput({ allSongData, setAllSongData }) {
    const bplOptions = [2, 3, 4, 6, 8, 12, 16];

    return (
        <>
            {allSongData &&
                <div className={`${style.inputSection}`}>
                    <label>Beats Per Line:</label>
                    <div className={`${style.choiceContainer} ${style.beatsPerLine}`}>
                        {bplOptions.map((bpl, i) => {
                            // We need to convert beatsPerLine to a number to check equality

                            return (
                                <div className={`flex ${style.choiceSingle}`} key={i}>
                                    <input
                                        className='focusable'
                                        type="radio"
                                        id={bpl}
                                        name="beatsPerLine"
                                        value={bpl}
                                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })}
                                        checked={bpl === +allSongData.beatsPerLine}
                                    />
                                    <label htmlFor={bpl}>{bpl}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}
