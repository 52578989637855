import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function SegmentRepeatedInput({ allSongData, setAllSongData, index }) {
    const { fullChordsLyrics = [{}, {}] } = allSongData;

    function updateRepeatedStatus(e) {
        const newArray = [...fullChordsLyrics];
        newArray[index].repeated = e.target.value;
        setAllSongData({ ...allSongData, fullChordsLyrics: newArray });
    }

    return (
        <div className={style.repeatedStatus}>
            <h2>Is this segment repeated?</h2>
            <div>
                <label htmlFor="n">
                    <input
                        className='focusable'
                        type="radio"
                        // anything but yes is 'no', thus by default it will be 'no'
                        checked={fullChordsLyrics[index].repeated !== 'y'}
                        onChange={updateRepeatedStatus}
                        name={`repeated${index}`} value="n" id="n" />
                    No
                </label>
            </div>
            <div>
                <label htmlFor="y">
                    <input
                        className='focusable'
                        type="radio"
                        checked={fullChordsLyrics[index].repeated === 'y'}
                        onChange={updateRepeatedStatus}
                        name={`repeated${index}`} value="y" id="y" />
                    Yes
                </label>
            </div>
        </div>

    )
}
