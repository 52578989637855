import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function SubmitButtons({allSongData, setAllSongData}) {
    return (
        <div className={style.submitBtnsContainer}>
            {/* Set finished status to 'n' in case he wants it to revert to draft */}
            {/* <button onClick={() => setAllSongData({...allSongData, finished: 'h'})}>Submit to DB</button> */}
            <button onClick={() => setAllSongData({...allSongData, finished: 'n'})}>Save as Draft</button>
            <button onClick={() => setAllSongData({...allSongData, finished: 'y'})} 
                className={style.submitBtn}>Publish</button>
        </div>
    )
}
