import React, {useState, useEffect} from 'react'
import style from 'css/user/FeaturedChords.module.css';
import { useHistory } from 'react-router-dom';
import generateAlbumImageUrl from 'generateAlbumImageUrl';

export default function FtrChrdsMiniDisp({ song, setMainImg, index, active, mainImageIntervalRef }) {
    const history = useHistory();
    const activeClass = active ? style.ftrChordsSingleActive : '';

    const [data, setData] = useState({songName: '', artistName: '', slug: '', imageUrl: '', title: ''})

    useEffect(() => {
        if(song) {
            const imageUrl = generateAlbumImageUrl(song);
            setData({songName: song.songName, artistName: song.artistName, slug: song.slug, imageUrl, title: song.albumName})
        }
    }, [song])

    
    return (
        <div className={`${style.featuredChordSingle} ${activeClass}`} 
        onClick={() => {
            clearInterval(mainImageIntervalRef.current);
            setMainImg(index);
        }}>
            <div className={style.containerDiv}>

                <img src={data.imageUrl} alt={data.title} title={data.title} />
                <div className={style.ftrChordsSongInfoDiv}>
                    <p className={style.songName}>{data.songName}</p>
                    <p className={style.artistName}><span>Artist: </span>{data.artistName}</p>
                </div>

                <button onClick={() => { history.push(`./songs/${data.slug}`) }} className="btn1"><span></span></button>

            </div>
        </div>
    )
}
