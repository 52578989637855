import './App.css';
import { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LayoutUser from './components/user/LayoutUser';
import LayoutAdmin from './components/admin/LayoutAdmin';
import ScrollToTop from 'components/user/ScrollToTop';
// import useWindowData from './useWindowData';
import Helmet from 'react-helmet';


function App() {

    const [user, setUser] = useState('mo');


    // const { device } = useWindowData();
    // console.log(device);

    return (
        <div className="App">
            <Helmet>
                <meta name="description" content="A music site with FREE chords to Jewish songs. Check out our selection of content from current artists. Includes auto-scroller." />
            </Helmet>
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route path="/admin">
                        <LayoutAdmin user={user} setUser={setUser} />
                    </Route>
                    <Route path="/">
                        <LayoutUser user={user} setUser={setUser} />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
