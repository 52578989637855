import React, { useState } from 'react'
import {Link } from 'react-router-dom';
import style from 'css/user/MobileMenu.module.css';

export default function MobileMenu({ mobileMenuMounted, setMobileMenuMounted, setMobileMenuShowing }) {

    // const [activeButton, setActiveButton] = useState('home');
    const [chordsOptionsOpen, setChordsOptionsOpen] = useState(false);
    const [chordsOptionsMounted, setChordsOptionsMounted] = useState(false);

    return (
        <div className={style.mobileMenuOverlay} onClick={(e) => {
            // console.log('mobile overlay', e.nativeEvent)

            // This seems hacky, but it's a simple solution for the time being.
            // Here we are checking the length of the event path (i.e. how many levels of nested elements).
            // If it's equal to 8, that means user clicked on overlay, but not the part that includes the menu itself.
            // If it's not equal to 8 (i.e. greater), the user clicked the mobile menu, so don't close it.
            if (e.nativeEvent.path.length === 8) {
                setMobileMenuMounted(false)
            }
        }}>

            <div className={`${mobileMenuMounted ? style.mounted : style.unmounted} ${style.mobileMenu}`}
                onAnimationEnd={() => {
                    if (!mobileMenuMounted) {
                        setMobileMenuShowing(false)
                    }
                }}
            >
                <div className={style.mobileMenuContent}>
                    {/* <div
                        className={`${style.menuButtonContainer} ${activeButton === 'home' ? style.menuButtonActive : ''}`}
                        onClick={() => setActiveButton('home')}
                    >
                        <div className={`${style.homeButton} ${style.menuButton}`}>
                            <i className="fas fa-home"></i>
                        </div>
                        <p>Menu</p>
                    </div>

                    <div
                        className={`${style.menuButtonContainer} ${activeButton === 'account' ? style.menuButtonActive : ''}`}
                        onClick={() => setActiveButton('account')}>
                        <div className={`${style.acccountButton} ${style.menuButton}`}>
                            <i className="fas fa-user"></i>
                        </div>
                        <p>Account</p>
                    </div> */}

                    <div className={style.menuSelectContainer}>
                        <div className={style.menuSelect}>
                            <div><Link to="/about">About</Link></div>
                            <div></div>
                        </div>
                        <div className={`${style.menuSelect} ${style.chordsSelect} `}
                            onClick={() => {
                                // console.log('chordsOptionsMounted', chordsOptionsMounted);
                                // console.log('chordsOptionsOpen', chordsOptionsOpen)
                                setChordsOptionsMounted(!chordsOptionsMounted);
                                if (!chordsOptionsOpen) {
                                    setChordsOptionsOpen(true);
                                    // setChordsOptionsMounted(true);
                                }
                            }}
                            onAnimationEnd={() =>{
                                if(!chordsOptionsMounted){
                                    setChordsOptionsOpen(!chordsOptionsOpen);
                                    // setChordsOptionsMounted(!chordsOptionsMounted);
                                }
                            }}
                            >
                            <div className={`${style.chordsSelectHeader} ${chordsOptionsMounted ? style.chordsSelectActive : ''}`}>
                                <div>Chords</div>
                                <div className={style.chordsOptionsArrow}>&#8250;</div>
                            </div>

                            {chordsOptionsOpen ?
                                <div className={`${style.chordsOptionsContainer} ${chordsOptionsMounted ? style.chordsOptionsMounted : style.chordsOptionsUnmounted}`}>
                                    {/* <div className={style.chordsOptionsPositionedContainer}> */}
                                        <div className={style.chordsSelectOption}><Link to="/all-chords">All Chords</Link></div>
                                        <div className={style.chordsSelectOption}><Link to="/new-chords">New Chords</Link></div>
                                        <div className={style.chordsSelectOption}><Link to="/featured-chords">Featured Chords</Link></div>
                                    {/* </div> */}
                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
