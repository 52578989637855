import React, {useState, useEffect} from 'react'
import style from 'css/user/SongThumb.module.css';
import { useHistory } from 'react-router-dom';
import SongDetails from 'components/user/SongDetails';
import generateAlbumImageUrl from 'generateAlbumImageUrl';

export default function SongThumbSingle({ song }) {

    const history = useHistory();
    const [data, setData] = useState({songName: '', slug: '', imageUrl: '', title: ''})

    useEffect(() => {
        if(song) {
            const imageUrl = generateAlbumImageUrl(song);
            setData({songName: song.songName, slug: song.slug, imageUrl, title: song.albumName})
        }
    }, [song])

    return (
        <div className={style.thumbSingleContainer}>
            <img src={data.imageUrl} alt={data.title} title={data.title} />
            <h1>{data.songName}</h1>
           
            <SongDetails song={song} />

            <button className="btn1" onClick={() => history.push(`/songs/${data.slug}`)}>View Chord Sheet</button>
        </div>

    )
}
