import React from 'react'
import style from 'css/user/FeaturedChords.module.css';

export default function FtrChrdsBtn({ index, active, setMainImgIndex, mainImageIntervalRef }) {
    const activeClass = active ? style.ftrChordsBtnActive : '';
    // console.log(active);
    return (
        <button onClick={() => {
            clearInterval(mainImageIntervalRef.current);
            setMainImgIndex(index)
        }} className={activeClass}></button>
    )
}
