import React /* , { useEffect, useRef } */ from 'react'
import style from 'css/user/SortByDropdown.module.css';
import selected from 'css/user/SelectedDiv.module.css';


export default function SortByDropdown() {
    const categories = ['All', 'Featured', 'Newest', 'A-Z', 'Z-A'];

    return (
        <div className={style.container} >
            {categories.map((c, i) => {
                return (
                    <div key={i} className={selected.container}>
                        <input type="radio" name="sortBy" className={selected.checkbox} value={c} />
                        <span></span>
                        <div className={style.categorySingle}>{c}</div>
                    </div>
                )
            })
            }
        </div>
    )

}
