import React, {useState, useEffect} from 'react'
import style from 'css/user/NewChords.module.css';
import { useHistory } from 'react-router-dom';
import useWindowData from 'useWindowData';
import generateAlbumImageUrl from 'generateAlbumImageUrl';

export default function NewChordsThumb({ song }) {
    const { device } = useWindowData();
    const history = useHistory();

    const [data, setData] = useState({songName: '', slug: '', imageUrl: '', title: ''})

    useEffect(() => {
        if(song) {
            const imageUrl = generateAlbumImageUrl(song);
            setData({songName: song.songName, slug: song.slug, imageUrl, title: song.albumName})
        }
    }, [song])

    return (

        <div className={style.thumbnail}>
            <img src={data.imageUrl}
                alt={data.title} title={data.title}
            onClick={() => {
                if(device === 'mobile')   {
                    history.push(`./songs/${data.slug}`)
                }
            }
         } />
            <div className={style.songName}>{data.songName}</div>
            {device !== 'mobile' ?
                <button className="btn1" onClick={() => history.push(`./songs/${data.slug}`)}>View Chord Sheet</button>
                : null
            }
        </div>
    )
}
