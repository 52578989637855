import React, { useState, useEffect } from 'react'
import style from 'css/admin/SubmitSong.module.css';
import executeFetch from 'executeFetch';
import generateAlbumImageUrl from 'generateAlbumImageUrl';

export default function AlbumInfoInput({ allSongData, setAllSongData }) {
    const [albums, setAlbums] = useState([]);

    const defaultImage = process.env.PUBLIC_URL + '/images/no-image.png';
    const [previewImage, setPreviewImage] = useState(defaultImage);

    // once there is an artist, retrieve albums for that artist
    useEffect(() => {
        (async () => {
            if (allSongData && allSongData.artistName) {
                const albums = await executeFetch.get(`/search/albums?artist=${allSongData.artistName}`);
                setAlbums(albums.map(a => a.albumName));
            }
        })();
    }, [allSongData])

    useEffect(() => {
        /* Here it might be helpful to add some testing to the url in case it doesn't lead to a real image. On the other hand, maybe it's better this way so we know when there's an issue. The old AlbumInfoInput file has some kind of testing for this. */
        if (allSongData.artistName && allSongData.albumName) {
            const url = generateAlbumImageUrl(allSongData);
            setPreviewImage(url);
        }
    }, [allSongData.artistName, allSongData.albumName])

    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>

                    {/* input for Album Name */}
                    <input required className='focusable' name="albumName" value={allSongData.albumName} list="albumNames" placeholder='Enter Album Name' onChange={e => setAllSongData({ ...allSongData, albumName: e.target.value })} />
                    <datalist id="albumNames">
                        {albums.map((album, i) => (
                            <option key={i} value={album}>{album}</option>
                        ))}
                    </datalist>

                    {/* Preview (img) and input for album image */}
                    <img className={style.albumImagePreview} src={previewImage} alt={allSongData.albumName || 'No album'} title={allSongData.albumName || 'No album'}/>
                    <input className='focusable' name="albumImage" id="albumCover"
                        type="file" accept=".jpg, .jpeg, .png, .webp"
                        onChange={e => {
                            // #########checkImageSize(e); image validation here

                            // add image to state
                            setAllSongData({ ...allSongData, [e.target.name]: e.target.files[0] });
                            // Convert file to ObjectURL
                            const url = URL.createObjectURL(e.target.files[0]);
                            // console.log(url);
                            // Set file as preview image
                            setPreviewImage(url);
                        }} />
                    <label id={style.albumCoverLabel} htmlFor='albumCover'>Upload a file</label>

                </div>
            }
        </>
    )
}
