import style from 'css/user/SongChordPage.module.css';
import React from 'react';

export default function formatSong(song) {
    // parse the JSON from the db
    const parsedSong = JSON.parse(song.fullChordsLyrics);

    // map the song into a new array
    const formattedSong = parsedSong.map((segment, i) => {
        let {chordsLyrics} = segment;

        // generate span elements around the chords using special regex to target chord names
        // NOTE: This may not be targeting all types of chord names. Be on the lookout.
        for (let i = 0; i < chordsLyrics.length; i += 2) {
            // Create wrapper element for individual chords
            chordsLyrics[i] = chordsLyrics[i].replace(/([A-G][b|#]*[m|maj|min]*[5-7]*)/g, `<span class=${style.songChord}>$1</span>`)
            // Create wrapper element for each line of the lyrics
            chordsLyrics[i + 1] = `<span class=${style.songLyric}>${chordsLyrics[i + 1]}</span>`;
        }

        // join the lyrics on new line characters
        // Note: I formerly wrote join('\n\r'), using both newline and return characters. For some reason, when updating the HTML (dangerouslySetInnerHTML), this results in an extra line. Hopefully, we won't have any problems using just the newline character.
        const joinedLyrics = chordsLyrics.join('\n');

        // set the class names
        const elemClass =
            segment.type === 'verse' ? style.songVerse :
            segment.type === 'chorus' ? style.songChorus :
            segment.type === 'bridge' ? style.songBridge :
            segment.type === 'ending' ? style.songEnding : '';

        return (
            <React.Fragment key={i}>
                <div
                    className={`${elemClass} ${style.songSegment}`}
                    /* data-repeated={segment.repeated}
                    data-beats-per-line={segment.beatsPerLine || song.beatsPerLine}
                    data-tempo={segment.tempo || song.tempo} */
                    dangerouslySetInnerHTML={{ __html: joinedLyrics }}
                >
                </div>
                {
                    segment.repeated === 'y' ? 
                    <div
                        className={`${elemClass} ${style.songSegment} ${style.repeatedSegment}`}
                        dangerouslySetInnerHTML={{ __html: joinedLyrics }}
                    />  : null
                }
            </React.Fragment>
        )
    })

    return formattedSong;
}