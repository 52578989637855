import React from 'react'
import style from 'css/admin/SubmitSong.module.css';
import SegmentTypeInput from './ChordsLyricsHandlers/SegmentTypeInput';
import SegmentRepeatedInput from './ChordsLyricsHandlers/SegmentRepeatedInput';
import RemoveSegmentButton from './ChordsLyricsHandlers/RemoveSegmentButton';
import AddSegmentInput from './ChordsLyricsHandlers/AddSegmentInput';

export default function ChordsLyricsSegmentsInput({ allSongData, setAllSongData }) {
    const { fullChordsLyrics = [{}, {}] } = allSongData; // destructuring to a default so that it's never undefined

    function updateFullSong(e) {
        // better names
        const index = +e.target.id;
        let chordsLyrics = e.target.value;

        const newArray = [...fullChordsLyrics];
        // update the local array
        newArray[index].chordsLyrics = chordsLyrics;
        // create proper deep copy (necessary for nested array)
        // const newArrayCopy = JSON.parse(JSON.stringify(newArray)); // needed this at some point, seems unnecessary https://stackoverflow.com/questions/62491607/cloned-arrays-affecting-react-state
        setAllSongData({ ...allSongData, fullChordsLyrics: newArray })
    }

    return (
        <>
            {allSongData &&
                <>
                    <h2>Enter chords and lyrics for different sections:</h2>
                    {
                        fullChordsLyrics.map((segment, index) =>
                            <div className={style.inputSection} key={index}>
                                <div className={style.songSegmentNumber}>{index + 1}.</div>

                                <SegmentTypeInput allSongData={allSongData} setAllSongData={setAllSongData} index={index} />

                                <textarea className={`focusable ${style.chordsLyrics}`}
                                    name={`chords_lyrics_${index}`} id={index}
                                    value={fullChordsLyrics[index].chordsLyrics}
                                    onChange={updateFullSong}
                                />

                                <SegmentRepeatedInput allSongData={allSongData} setAllSongData={setAllSongData} index={index} />

                                <RemoveSegmentButton allSongData={allSongData} setAllSongData={setAllSongData} index={index} />

                            </div>
                        )}

                    < AddSegmentInput allSongData={allSongData} setAllSongData={setAllSongData} />

                    <div className={style.songSegmentDivider} />
                </>
            }
        </>

    )
}