import React, { useState, useEffect } from 'react'
import style from 'css/admin/SubmitSong.module.css';
import tableStyle from 'css/admin/Table.module.css'
import executeFetch from 'executeFetch'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function CurrentSongs() {
    const [currentSongs, setCurrentSongs] = useState([]);

    useEffect(() => {
        (async () => {
            const currentSongs = await executeFetch.get('/retrieve-songs/current-songs')
            // console.log('songs', currentSongs);
            setCurrentSongs(currentSongs);
        })();
    }, [])



    return (
        <table className={`${tableStyle.flTable} ${style.table}`}>
            <thead>
                <tr>
                    <th>Song Name</th>
                    <th>Artist Name</th>
                </tr>
            </thead>
            <tbody>
                {currentSongs.map((s, i) => {
                    return (
                        
                            <tr key={i}>
                                <td><Link to={`/admin/submit-song/${s.slug}`}>{s.songName}</Link></td>
                                <td><Link to={`/admin/submit-song/${s.slug}`}>{s.artistName}</Link></td>

                            </tr>
                        
                    )
                })}
            </tbody>
        </table>

    )
}
