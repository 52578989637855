import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function CommentsInput({ allSongData, setAllSongData }) {
    return (
        <>
            {allSongData &&
                <div className={style.commentsContainer}>
                    <h3>Comments:</h3>
                    <textarea
                        className='focusable'
                        placeholder="Enter comments here"
                        name="comments"
                        value={allSongData.comments}
                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })}
                    />
                </div>
            }
        </>
    )
}
