import React from 'react'
import style from 'css/user/AllChords.module.css';
import SongThumbSingle from './SongThumbSingle';

export default function SongThumbGroup({songs}) {
    return (
        <div id={style.contentContainer}>
            {songs.map((s, i) => <SongThumbSingle song={s} key={i} />)}
        </div>
    )
}
