// code from stackoverflow: https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs

import { useState, useEffect } from 'react';

function getWindowData() {
    const { innerWidth: width, innerHeight: height } = window;
    // // ******* for development only. see https://stackoverflow.com/questions/36297612/window-innerwidth-in-chromes-device-mode
    // const width = window.visualViewport.width;
    // const height = window.visualViewport.height;

    return {
        width,
        height,
        device: width <= 414 ? 'mobile' : width <= 1024 ? 'tablet' : 'desktop'
    };
}

export default function useWindowData() {
    const [windowData, setWindowData] = useState(getWindowData());

    useEffect(() =>{
        function handleResize() {
            setWindowData(getWindowData());
            // console.log('size changed', getWindowData());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return windowData;
}