import React from 'react'
import style from 'css/admin/Dashboard.module.css'

export default function Dashboard() {
    return (
        <div className={style.main}>
            Welcome to the Dashboard
        </div>
    )
}
