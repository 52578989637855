import React from 'react'
import { NavLink } from 'react-router-dom';
import style from 'css/admin/Nav.module.css';

export default function NavAdmin({user, setUser}) {
    const logout = () =>{
        setUser('');
    }

    return (
        <nav className={style.nav}>
            <NavLink to="/admin">Dashboard</NavLink>
            <NavLink to="/admin/submit-song">Submit Song</NavLink>
            <NavLink to="/admin/song-drafts">Song Drafts</NavLink>
            <NavLink to="/admin/song-poll">Song Poll</NavLink>
            <div className={style.userInfo}>Logged in as {user.username || 'Stranger'} <span onClick={logout} className={style.logoutBtn}>Logout</span></div>
        </nav>
    )
}
