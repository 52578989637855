function sortSongsByValues(array, value) {
    array.sort((a, b) => {
        if (a[value] < b[value]) {
            return -1;
        } else if (b[value] > a[value]) {
            return 1;
        }
        return 0;
    })
    // need to create new array, otherwise React doesn't recognize it as a state change
    return [...array];
    // return array;
}

function filterSongs(songs, filters) {
    let newArray = [...songs];

    const keys = Object.keys(filters);
    // console.log(keys);
    const values = Object.values(filters);

    for (let i = 0; i < keys.length; i++) {

        newArray = newArray.filter(song => {
            // console.log('keys',keys);
            // console.log('values', values);
            // console.log(values[i]);
            // console.log(song[keys[i].toLowerCase()]);
            // console.log(!values[i].length, 'this song passes the filter', values[i], song[keys[i].toLowerCase], values[i].includes(song[keys[i]]));
            // console.log( values[i].includes(song[keys[i].toLowerCase()]));
            // if(Array.isArray(JSON.parse(song[keys[i]]))) {
            //     song[keys[i]] = JSON.parse(song[keys[i]]);
            // }
            /*
                We need to implement different logic based on which filter was selected. E.g. if it was tags,
                we want any song that contains any of the selected tags. But if he selected includedChords,
                we want songs that contain ONLY the selected chords.
            */

            switch(keys[i]){
                case 'includedChords':
                    const selectedChords = values[i];
                    const songChords = JSON.parse(song[keys[i]]);
                    // console.log('selectedChords', selectedChords, typeof selectedChords);
                    // console.log('songChords', songChords, typeof songChords);
                    return  !selectedChords.length ||  songChords.every(chord => selectedChords.includes(chord));
                case 'tags':
                    const selectedTags = values[i].map(v => v.toLowerCase());
                    const songTags = JSON.parse(song[keys[i]]);
                    // console.log('selectedTags', selectedTags, typeof selectedTags);
                    // console.log('songTags', songTags, typeof songTags);
                    return /* !selectedTags.length || */ songTags.some(tag => selectedTags.includes(tag));
                default:
                    const selectedValues = values[i];
                    const songValues = song[keys[i]];
                    // console.log('selectedValues', selectedValues);
                    // console.log('songValues', songValues);

                 return !selectedValues.length || selectedValues.includes(songValues)
            }

        });
    }

    // console.log(newArray);

    return newArray;
}

export {
    sortSongsByValues,
    filterSongs
}