import SongDetails from 'components/user/SongDetails';
import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router';
import style from 'css/user/SongChordPage.module.css';
import generateAlbumImageUrl from 'generateAlbumImageUrl';


export default function HeaderSection({ song }) {

    const [data, setData] = useState({songName: '', slug: '', imageUrl: '', title: ''})
    const history = useHistory();

    useEffect(() => {
        if(song) {
            // console.log(song);
            const imageUrl = generateAlbumImageUrl(song);
            setData({songName: song.songName, slug: song.slug, imageUrl, title: song.albumName})
        }
    }, [song])


    return song ? (
        <div className={style.headerContainer}>
            <div className={style.imageContainer}>
                <img src={data.imageUrl} alt={data.title} title={data.title} className={style.albumCoverImg} />
            </div>
            
            <div className={style.headerTextContainer}>
                <h1 className={style.songName}>{data.songName}</h1>

                {/* ********* some still need to fix positioning for SongDetails. for some reason, without artist name it looks ok */}
                <SongDetails song={song} songChordPage={true} />
            </div>

            <div className={style.allChordsLink} onClick={() => history.push('/all-chords')}>Return to All Chords</div>
        </div>
    ) : null
}
