import React, { useState, useEffect } from 'react'
import executeFetch from 'executeFetch'
import style from 'css/admin/SongDrafts.module.css';
import { useHistory } from 'react-router';

export default function SongDrafts() {

    const [drafts, setDrafts] = useState([]);
    const history = useHistory();

    const [localStorageDrafts, setLocalStorageDrafts] = useState([]);

    useEffect(() => {
        (async () => {
            const drafts = await executeFetch.get('/retrieve-songs/drafts')
            // console.log(drafts);
            setDrafts(drafts);

            if (localStorage.drafts) setLocalStorageDrafts(JSON.parse(localStorage.drafts));
        })();
    }, [])


    return (
        <div className={style.draftsContainer}>
            <h2>Database Drafts</h2>
            {drafts.map((d, i) => {
                return (
                    <div key={i} className={style.draftSingleDiv}
                        onClick={() => history.push(`/admin/submit-song/${d.slug}`)}>
                        <h3>{d.songName}</h3>
                        <h4>{d.artistName}</h4>
                        <p>{d.comments}</p>
                    </div>
                )
            })}
            <div className={style.separator} />
            <div className='flex-column'>
                <h2>Local Drafts</h2>
                {localStorageDrafts.map((d, i) => {
                    return (
                        <div key={i} className={style.draftSingleDiv}
                            onClick={() => history.push(`/admin/submit-song/${d.lsid}`)}>
                            <h3>{d.songName}</h3>
                            <h4>{d.artistName}</h4>
                            <p>{d.lsid}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
