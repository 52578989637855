import React, { useState, useRef } from 'react'
import style from 'css/user/FilterOptions.module.css';
import FilterDropdownMobile from './FilterDropdownMobile';

export default function FilterButtonMobile() {

    const [popupShowing, setPopupShowing] = useState(false);

    const ref = useRef(null);

    function handleOutsideClick(e) {
        /*
        Here we had a slight issue that the suboptions (e.g. artist names - 'Abie Rotenberg', etc.)
        are removed from the DOM before this listener executes. What would result is that the ref
        will never contain them, and therefore the computer thinks it is clicking away.
        The solution is to also check if the e.target still exists in the DOM. If it doesn't exist,
        it must be one of the elements that was just removed, i.e. one of the children of the ref.
        */
        if (ref.current && document.body.contains(e.target) && !ref.current.contains(e.target)) {
        // if (ref.current && !ref.current.contains(e.target)) {

            setPopupShowing(false);
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    const handleClick = () => {
        if (!popupShowing) {
            setPopupShowing(true); // may want to change this by the other ones also
            document.addEventListener('click', handleOutsideClick);
        }
    }

    return (
        <button ref={ref} className={style.mobileFilterBtn} onClick={handleClick}>
            Filter Results
            {popupShowing ? <FilterDropdownMobile /> : null}

        </button>
    )
}
