import React, { useState } from 'react'
import modal from 'css/user/ModalContent.module.css';
import useForm from 'useForm';


export default function ContactForm({ setModalShowing }) {
    const [values, handleValueChange] = useForm({ email: '', message: '' });
    const [response, setResponse] = useState('');
    const [responseClass, setResponseClass] = useState('');


    // *********** to enable form submit on pressing Enter. not working yet
    // const submit = e =>{
    //     if(e.key === 'Enter') {
    //         console.log('form submitted');
    //         e.preventDefault();
    //         sendMessage();
    //     }
    // }

    const sendMessage = async e => {
        e.preventDefault();
        // console.log(values)
        try {
            // console.log('sending a message');
            const r = await fetch('/operations/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            })
            if (r.ok) {
                // console.log('MESSAGE SENT', r);
                // if you want to see the response, it is in json format
                // ************ however, couldn't figure out how to process json from server
                // const info =  await r.json();
                // console.log('info', info);
                setResponseClass('success');
                setResponse('Message sent! We will get back to you shortly.')
                setTimeout(() => setModalShowing(false), 2000);
            } else {
                const text = await r.text();
                // console.log(text);
                setResponseClass('error')
                throw new Error(text || `${r.status} - ${r.statusText}`)
            }

        } catch (err) {
            // console.log('ERROR SENDING MESSAGE', err.message);            
            setResponse(err.message);
        }
    }

    return (
        <div className={`flex-column flex-center`}>
            <div className={`${modal.subHeader} margin-top-20`}>Need support or have something to share with us?</div>
            <form className={modal.form} onSubmit={sendMessage}>
                <div className={`${modal.inputSection} margin-top-30`}>
                    <label htmlFor="email">Email:</label>
                    <input className="input1" type="email" name="email" onChange={handleValueChange} value={values.email} />
                </div>
                <div className={`${modal.inputSection} margin-top-30`}>
                    <label htmlFor="message">Message:</label>
                    {/* <textarea onKeyPress={submit} className="input1" name="message" onChange={handleValueChange} value={values.message}/> */}
                    <textarea className="input1" name="message" onChange={handleValueChange} value={values.message} />
                </div>

                {response ? <div className={`${modal.messageResponse} ${modal[responseClass]}`}>{response}</div> : null}
                <input className="btn2 margin-top-40" disabled={!values.email || !values.message} type="submit" value="Send" />
            </form>
        </div>
    )
}
