import React from 'react'
import WelcomeSection from './HomePage/WelcomeSection'
import FeaturedChordsSection from './HomePage/FtrChrdsSection'
import NewChordsSection from './HomePage/NewChordsSection'
import PopularArtistsSection from './HomePage/PopularArtistsSection'
import Helmet from 'react-helmet';

export default function Home() {

    return (
        <>
            <Helmet>
                <title>Kumzitz Corner | Home</title>
            </Helmet>
            <WelcomeSection />
            <FeaturedChordsSection />
            <NewChordsSection />
            <PopularArtistsSection />
        </>
    )
}
