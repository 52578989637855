import React from 'react'
import style from 'css/user/Nav.module.css';

export default function Hamburger({ mobileMenuShowing, setMobileMenuMounted, setMobileMenuShowing }) {
    return (
        <div className={`${style.hamburgerContainer} ${mobileMenuShowing ? style.mobileMenuActive : ''}`}
            onClick={() => {      
                setMobileMenuMounted(!mobileMenuShowing);
                setMobileMenuShowing(true)
            }}>
            <i className="fas fa-bars"></i>

        </div>
    )
}
