// import React, { useState, useEffect } from 'react'
import style from 'css/user/FeaturedChords.module.css';
import FtrChrdsBtn from './FtrChrdsBtn';
// import generateAlbumImageUrl from 'generateAlbumImageUrl';

export default function FtrChrdsMainDisp({ songs, mainImgIndex, setMainImgIndex, mainImageIntervalRef }) {

    // const song = songs[mainImgIndex]; I don't think this line should work out here - the song will stay the same on each render lichora
    const buttons = [];
    for (let i = 0; i < songs.length; i++) {
        buttons.push(<FtrChrdsBtn key={i} index={i} active={i === mainImgIndex} setMainImgIndex={setMainImgIndex} mainImageIntervalRef={mainImageIntervalRef} />);
    }

    return (
        <>
            <div className={style.mainImgContainer}>
                {songs ? songs.map((s, i) => {
                    return (
                        <img key={i} src={s.imageUrl} i={i}
                            className={`${style.ftrChordsMainImage} ${i === mainImgIndex ? style.mainImgActive : ''}`}
                            alt={s.albumName} title={s.albumName} />
                    )
                })
            : null}
            </div>
            <br />
            <div id={style.ftrChordsBtns}>
                {buttons}
            </div>
        </>
    )
}
