import React from 'react'
import { Link } from 'react-router-dom';

export default function SongRowTable({ songDetails, tableColumns }) {
    // console.log(songDetails);
    // const {name, artist_name, recommendedKey:key, includedChords, recommendedKey, difficulty, slug} = songDetails;
    return (
        <tr>
            {/* <td><Link to={`/songs/${slug}`}>{name}</Link></td>
            <td>{artist_name}</td>
            <td>{key}</td>
            <td>{includedChords ? JSON.parse(includedChords).join(', ') : ''}</td>
            <td>{difficulty}</td> */}
            {tableColumns.map((tc, i) => {
                switch (tc) {
                    case 'name':
                        return <td key={i}><Link to={`/songs/${songDetails.slug}`}>{songDetails.songName}</Link></td>;
                    case 'includedChords':
                    case 'tags':
                        return <td key={i}>{songDetails[tc] ? JSON.parse(songDetails[tc]).join(', ') : ''}</td>;
                    default:
                        return <td key={i}>{songDetails[tc]}</td>;
                }
            })}
        </tr>
    )
}
