
/* I would prefer to only pass in the necessary values, i.e. artistName and albumName. But it means refactoring a bunch of files. I hope to get around to it someday. */
export default function generateAlbumImageUrl (allSongData, includeFileName = true) {
    if(!allSongData || !allSongData.artistName || !allSongData.albumName) return;
    const fileName = includeFileName ? '450.webp' : '';

    const {artistName, albumName} = allSongData;

    // eliminate spaces, combine uppercased first letter with remainder, and join once again
    const camelizedArtistName = artistName.split(' ').map(w => w[0]?.toUpperCase() + w.substring(1) ).join('');
    
    // eliminate spaces, combine uppercased first letter with remainder, join once again, and remove special characters (e.g. ' from A Mother's Promise)
    const camelizedAlbumName  = albumName.split(' ').map(w => w[0]?.toUpperCase() + w.substring(1) ).join('').replace(/\W/g, '')
    
    const url = `${process.env.PUBLIC_URL}/images/artists/${camelizedArtistName}/albums/${camelizedAlbumName}/${fileName}`;
    // console.log(url);
    
    return url;
}