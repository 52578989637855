import React, { useState, useEffect, useRef } from 'react'
import style from 'css/user/SongChordPage.module.css';
import fontResizer from './utils/fontResizer';
import AutoScroller from './AutoScroller';
import formatSong from './utils/formatSong';

export default function ChordsLyrics({ song }) {

    const elem = useRef(null);
    const [fontStyle, setFontStyle] = useState({});
    const [scrolling, toggleScrolling] = useState(false);
    const defaultTempo = 120;

    const [fullSongElemArray, setFullSongElemArray] = useState();
    // console.log(song.fullChordsLyrics);

    useEffect(() => {

        if (song) {
            // format song (e.g. classes, separate divs)
            const formattedSong = formatSong(song);
            // console.log('formatted song', formattedSong);
            setFullSongElemArray(formattedSong);

            // set font size based on screen width
            setFontSize();

            // add listener to update font size on screen resize
            window.addEventListener('resize', setFontSize)

            return () => {
                window.removeEventListener('resize', setFontSize)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [song])

    // This was made into a function because it is used twice, and also so we can remove the resize event listener
    function setFontSize() {
        const fontStyle = fontResizer(song.fullChordsLyrics, elem.current)
        setFontStyle(fontStyle);
    }

    return song ? (
        <div className={style.bodyContainer}>
            <div className={style.chordsLyricsContainer}>
                <h1 className={style.songTitle}>{song.songName}</h1>

                {song.capoPlacement ? <h3 className={style.songCapoPlacement}>Capo {song.capoPlacement}</h3> : null}

                <div
                    id="chordsLyrics"
                    ref={elem}
                    style={fontStyle}
                    className={`${style.chordsLyrics} ${scrolling ? style.scrollingActive : ''} `}>
                    {fullSongElemArray}
                </div>

                <AutoScroller bpl={song.beatsPerLine} scrolling={scrolling} toggleScrolling={toggleScrolling} tempo={song.tempo || defaultTempo} />
            </div>
        </div>
    ) : null
}