import executeFetch from './executeFetch';

/* This module is to simplify the process of retrieving the filter values
and their text forms. e.g. to get all artists in the db and to ensure that 
the display text is "Artist" rather than "artist_name" */

export async function getFilterValues(filter) {
    let url;

    switch (filter) {
        case 'artistName':
            url = '/search/artists';
            break;
        case 'recommendedKey':
            url = '/search/keys';
            break;
        case 'tags':
            url = '/search/tags';
            break;
        case 'difficulty':
            return ['beginner', 'intermediate', 'advanced'];
        case 'includedChords':
            url = '/search/included-chords';
            break;
        default:
            return;
    }


    const filterValues = await executeFetch.get(url);
    return filterValues;
}

export function getFilterText(filter) {
    switch (filter) {
        case 'recommendedKey':
            return 'Key';
        case 'artistName':
            return 'Artist';
        default:
            return filter.replace('_', ' ');
    }
}