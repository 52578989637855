import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import style from 'css/user/Nav.module.css';

/* Search Bar is in a separate component because it is used once in each condition
in conditional render inside Nav component.
Also, it's unique api logic is best encapsulated, not interfering with Nav component.
 STYLING is in Nav.module.css
*/
export default function SearchBar() {
    const [searchTerm, setSearchTerm] = useState('')
    const history = useHistory();

    const search = e => {
        e.preventDefault();
        /* 
        Desired functionality: If there's no search term, don't search. 
        If there is a search term, clear the search field and push to search page.*/
        if (searchTerm) {
            history.push(`/search?q=${searchTerm}`);
            setSearchTerm('');
            // console.log(e);
            e.target.reset();
            e.target[0].blur();
        }

    }

    return (
        <div className={style.navSearchDiv}>
            <form onSubmit={search}>
                <input type="text" placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className={style.navMagGlass} >
                    <i className="fas fa-search"></i>
                </button>
            </form>
        </div>
    )
}
