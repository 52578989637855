import {sortSongsByValues, filterSongs} from '../reducerUtils';




const defaultState = {
    songs: [],
    filters: {},
    filteredSongs: [],
    sortBy: '',
    searchResultsSongs: []
}

const songsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'INITIALIZE_SONGS':
            const alphabetizedSongs = sortSongsByValues(action.payload, 'songName');
            return { ...state, songs: alphabetizedSongs };
        case 'SORT_SONGS':
            const sortedSongs = sortSongsByValues(state.songs, action.payload);
            return { ...state, songs: sortedSongs };
        case 'FILTER_SONGS':
            state = { ...state, filters: { ...state.filters, ...action.payload } };
            const filteredSongs = filterSongs(state.songs, state.filters);
            return { ...state, filteredSongs };
        case 'SET_SEARCH_RESULTS':
            return {...state, searchResultsSongs: action.payload};
        default:
            return state;
    }
}

export default songsReducer;