import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function SlugInput({ allSongData, setAllSongData }) {
    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <input className='focusable'
                        name="slug"
                        value={allSongData.slug}
                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })} placeholder='Slug goes here'
                        readOnly disabled />
                </div>
            }
        </>
    )
}
