import React from 'react'
import style from 'css/user/SubscribeButton.module.css';
import SubscribeForm from './SubscribeForm';

export default function SubscribeButton({setPopup, setModalShowing}) {
    return (
        <button className={style.subscribeBtn} onClick={() => {
            setPopup(<SubscribeForm setModalShowing={setModalShowing} />)
            setModalShowing(true); 
        } }>Subscribe to Our Newsletter</button>
    )
}
