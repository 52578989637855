import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function TagsInput({ allSongData, setAllSongData }) {
    const {tags = []} = allSongData;

    const tagOptions = ['Kumzitz','Hartzig', 'Oldies', 'Tanz / Dance', 'English', 'Yiddish', 'Slow', 'Medium', 'Fast', 'Featured', 'Poll Winner', 'Celebration / Simcha', 'Wedding / Chasan V\'Kallah', 'Holiday / Yom Tov', 'Chanuka', 'Purim', 'Pesach', 'Shavuos', 'Sukkos', 'Nechama', 'Yomim Nora\'im'];

    function updateSelectedTags(e) {
        // console.log(e.target.value);
        let newArray = [...tags];
        e.target.checked ? 
            newArray.push(e.target.value) : // if checked, add to array
            newArray = newArray.filter(tag => tag !== e.target.value); // else, remove from array
        
        setAllSongData({ ...allSongData, tags: newArray });
    }

    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <label>Song Tags:</label>
                    <div className={style.choiceContainer}>
                        {tagOptions.map((tag, i) => {
                            tag = tag.toLowerCase();
                            return (
                                <div className={`flex ${style.choiceSingle}`} key={i}>
                                    <input
                                        type="checkbox"
                                        name="tags"
                                        value={tag}
                                        className='focusable'
                                        onChange={updateSelectedTags}
                                        checked={tags.includes(tag)}
                                    />
                                    <label htmlFor={tag}>{tag}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    )
}