import React, { useState } from 'react'
import modal from 'css/user/ModalContent.module.css';

export default function SubscribeForm({setModalShowing}) {

    const [email, setEmail] = useState('');
    const [response, setResponse] = useState('');
    const [responseClass, setResponseClass] = useState('');

    const subscribe = async e => {
        e.preventDefault();
        // console.log(email)
        try {
            // console.log('subscribing');
                const response = await fetch('/operations/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email})
            })
            if(response.ok) {
                // console.log('ALL GOOD', response);
                setResponseClass('success')
                setResponse('Your contact info has been added. Thanks for joining!')
                setTimeout(() => setModalShowing(false), 2000);
            } else {
                // console.log('raw response', r);
                const text = await response.text();
                // console.log('text response', text);
                // const json = await r.json();
                // console.log('json', json)
                setResponseClass('error')
                throw new Error(text || `${response.status} - ${response.statusText}`)
            }
            
        } catch(err) {
            // console.log('ERROR', err.message);            
            setResponse(err.message);
            setTimeout(() => setResponse(''), 2000);
        }
    }

    // For consideration: should we add fields for first name and last name as per the initial Mailchimp setup?
    // would just require a little styling

    return (
        <div className={`flex-column flex-center`}>
            {/* subheader has margin-top-30 here because otherwise it looks bad with the X icon */}
            <div className={`${modal.subHeader} margin-top-30`}>Want to be the first to know about our new releases?</div>
            <h1 className={`${modal.tagline} margin-top-20`}>Sign up for our newsletter!</h1>

            <form className={modal.form} onSubmit={subscribe}>
                <input type="hidden" name="u" value="9583b699ad5a9e25632c262fb" />
                <input type="hidden" name="id" value="1c1bd0b4c9" />

                <div className={`${modal.inputSection} margin-top-30`}>
                    <label for="email">Email:</label>
                    <input type="email" name="MERGE0" onChange={e=> setEmail(e.target.value)} required className="input1" />
                </div>
                {response ? <div className={`${modal.messageResponse} ${modal[responseClass]}`}>{response}</div> : null}
                <input className="btn2 margin-top-30" disabled={!email.length} type="submit" value="Sign Me Up!" />
            </form>
        </div>
    )
}
