import { filterSongs } from 'actions';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import style from 'css/user/FilterDropdownMobile.module.css';
import {getFilterValues} from '../../../getFilters';

export default function FilterDropdownMobileSuboptions({ filter }) {
    const [suboptions, setSuboptions] = useState([]);
    let suboptionsElems;

    const dispatch = useDispatch();
    let { [filter]: selectedFiltersFromStore } = useSelector(state => state.songsReducer.filters);
    selectedFiltersFromStore = selectedFiltersFromStore || [];

    function isSelected(value) {
        return selectedFiltersFromStore.some(sf => sf === value);
    }

    const handleInputChange = e => {
        // console.log(e.target.name, filter);
        const elems = Array.from(document.querySelectorAll(`.${filter}`));
        const selectedFilters = { [filter]: elems.filter(elem => elem.checked === true).map(elem => elem.name) };
        // console.log(selectedFilters);
        dispatch(filterSongs(selectedFilters))
    }

    // once dropdown is live, get all options from db
    useEffect(() => {
        (async () => {
            const suboptions = await getFilterValues(filter);
            setSuboptions(suboptions);
        })();
    }, [])

    // generate divs from all options
    switch (filter) {
        case 'difficulty':
            suboptionsElems = suboptions.map((o, i) =>
            (
                <div key={i} className={style.suboptionSingleContainer}>
                    <label htmlFor={o} className={`${style.suboptionLabel} ${o}`}>
                        <input
                            id={o}
                            checked={isSelected(o)}
                            onChange={handleInputChange}
                            name={o}
                            type="checkbox"
                            className={`${style.suboptionCheckbox} ${filter}`} />
                    </label>
                </div>
            ))
            break;
        default:
            suboptionsElems = suboptions.map((o, i) =>
            (
                <div key={i} className={style.suboptionSingleContainer}>
                    <label htmlFor={o} className={style.suboptionLabel}>
                        <input
                            id={o}
                            checked={isSelected(o)}
                            onChange={handleInputChange}
                            name={o}
                            type="checkbox"
                            className={`${style.suboptionCheckbox} ${filter}`} />
                        {o}
                    </label>
                </div>
            ))
            break;
    }

    return (
        <div className={`${style.suboptionsAllContainer}`} >
            {suboptionsElems}
        </div>
    )
}
