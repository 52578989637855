import React from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function TempoInput({ allSongData, setAllSongData }) {
    const URL = "https://www.justinguitar.com/tap-tempo-bpm-tool"

    const openLink = () => window.open(URL, '_blank');


    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <label htmlFor="tempo">Select a recommended tempo:</label>
                    <div className={style.tempoChildContainer}>
                    <a target="_blank" rel="noreferrer" href={URL}>Justin Guitar Tap Tempo Tool</a>
                        <input
                            name="tempo"
                            type="number" min="50" max="200"
                            // onFocus={openLink}
                            className={`${style.tempo} focusable`}
                            value={allSongData.tempo}
                            onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })} />
                    </div>
                </div>
            }
        </>
    )
}
