export default function fontResizer(fullChordsLyrics, elem) {

    // declare variables for calculation later
    const widthHeightFactor = 1.75; // the factor that the height is greater than the width (12 / 6.77) - we need to multiply the width by this number to get the proper font size. The larger this number, the less space between the end of the line and the edge of the screen.
    const idealFontSize = 18;
    
    // parse the full song into a Javascript object
    const parsedSong = JSON.parse(fullChordsLyrics)
    // console.log('parsedSong', parsedSong);

    // combine chords_lyrics from all entries to create one text array
    let textArray = [];
    parsedSong.forEach(segment => textArray = textArray.concat(segment.chords_lyrics || segment.chordsLyrics))
    // console.log(textArray);

    // get the width of our container element
    const elemWidthString = window.getComputedStyle(elem).getPropertyValue('width');
    // console.log('width', elemWidth, typeof elemWidth, typeof +elemWidth);
    
    // get the line from the song that has the most characters 
    const longest = textArray.reduce((a, b) => a.length > b.length ? a : b);
    // console.log(longest, longest.length, typeof longest.length, typeof +longest.length);
    
    /*
    A small explanation of how we are calculating: At first glance, it would seem that all we need for the font size is the width of the container divided by the length of the longest line of characters. Since we are using a monospaced font, it simplifies things more, as each character is guaranteed to be the same size (i.e. no difference between characters like 'f' and 'm').
    However, as it happens, the font size we are left with will only apply to the height of each character. The width to height ratio in our current font (Anonymous Pro) is 6.77 to 12, which means the height is almost double the width. Thus, if we would leave the elemWidth/longest.length figure as is for the font size, we would be getting a number almost half the size of what we require.
    In other words, the calculation of elemWidth/longest.length leaves us with a desired figure of pixels for the width of each character. But this figure will be interpreted as a font size for the height, not the width. Therefore, we must increase the result by a factor of nearly double (12 / 6.77 = 1.77 - we are using 1.8) in order that the width of each character should be our desired number of pixels.
    */

    const elemWidthNum = +elemWidthString.replace('px', '');
    // desiredWidthPixels = the ideal number of pixels the width of each character should be
    const desiredWidthPixels = elemWidthNum / +longest.length;
    
    // maximumFeasibleSize = the largest the font can be without spilling off the screen
    const maximumFeasibleSize = desiredWidthPixels * widthHeightFactor;
    
    // since our ideal font size is 18px, if maximumFeasibleSize is greater then just use 18px
    const finalFontSize = Math.min(maximumFeasibleSize, idealFontSize);

    return {
        fontSize: `${finalFontSize}px`
        // fontSize: `${maximumFeasibleSize}px`
    };
}