import React from 'react'
import style from 'css/user/ModalContent.module.css';

export default function ModalContent({ popup, setModalShowing }) {
    return (
        <div className={style.overlay}>
            <div className={style.content}>
                <span className={style.exitIcon} onClick={() => setModalShowing(false)}>&#x2716;</span>
                {popup}
            </div>

        </div>
    )
}
