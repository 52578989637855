import React from 'react'
import style from 'css/user/AllChords.module.css';

export default function PageNumbers() {

    /* need to implement logic that will make page numbers become disabled and enabled
    also that will change the page numbers once all songs are loaded
    maybe get bigger arrows, too
    */
    return (
        <div className={style.pageNumberContainer}>
            <span className={`${style.leftArrow} ${style.arrowDisabled}`}>&larr;</span>
            <div className={style.numbersDiv}>
                <span className={`${style.pageCurrent}`}>1 </span>
                of
                 <span className={`${style.pageTotal}`}> 6</span>
            </div>
            <span className={`${style.leftArrow}`}>&rarr;</span>
        </div>
    )
}
