import React from 'react';
import style from 'css/user/HeroImage.module.css';

export default function HeroImage({img, header}) {
    const specificClass = header === 'All Chords' ? style.allChords : style.searchResults;

    return (
        <div className={style.container}>
            <h1 className={`${style.header} ${specificClass}`}>{header}</h1>
            <img alt="" className={style.heroImage} src={img} />
        </div>
    )
}
