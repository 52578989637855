import React from 'react'
import { songSegmentCategories } from '../../../../constants'

/* *******I think this is working... */

export default function SegmentTypeInput({allSongData, setAllSongData, index}) {
    const {fullChordsLyrics = [{}, {}] } = allSongData; // destructuring to a default so that it's never undefined

    function updateSegmentType(e) {
        const newArray = [...fullChordsLyrics];
        newArray[index].type = e.target.value;
        setAllSongData({...allSongData, fullChordsLyrics: newArray});
    }

    return (
        <>
            <input className='focusable capitalize datalist-input'
                name={`type_${index}`}
                value={fullChordsLyrics[index].type}
                defaultValue={index % 2 === 0 ? 'verse' : 'chorus'} // alternate even and odd
                list="types"
                placeholder='Enter Segment Type'
                onChange={updateSegmentType}
            />

            <datalist id="types">
                {songSegmentCategories.map((c, i) => (
                    <option className='capitalize' key={i} value={c.toLowerCase()}>{c}</option>
                ))}
            </datalist>
        </>
    )
}
