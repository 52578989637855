import React, { useState, useEffect } from 'react'
import ChordsLyricsSegmentsInput from './SubmitSong/ChordsLyricsSegmentsInput';
import SongNameInput from './SubmitSong/SongNameInput';
import ArtistNameInput from './SubmitSong/ArtistNameInput';
import AlbumInfoInput from './SubmitSong/AlbumInfoInput';
import DifficultyInput from './SubmitSong/DifficultyInput';
import RecommendedKeyInput from './SubmitSong/RecommendedKeyInput';
import CapoPlacementInput from './SubmitSong/CapoPlacementInput';
import TempoInput from './SubmitSong/TempoInput';
import BeatsPerLineInput from './SubmitSong/BeatsPerLineInput';
import TagsInput from './SubmitSong/TagsInput';
import StrummingPatternInput from './SubmitSong/StrummingPatternInput';
import CommentsInput from './SubmitSong/CommentsInput';
import executeFetch from 'executeFetch';
import { useHistory, useParams } from 'react-router';
import CurrentSongs from './CurrentSongs';
import { convertToDBformat } from './SubmitSong/convertToDBformat';
import SlugInput from './SubmitSong/SlugInput';
import SubmitButtons from './SubmitSong/SubmitButtons';
import style from 'css/admin/SubmitSong.module.css';
import convertDraftFromDB from './SubmitSong/convertDraftFromDB';

export default function SubmitSong(/* { user } */) {
    const { slug } = useParams();
    const [allSongData, setAllSongData] = useState({});
    const history = useHistory();
    // retrieve draft if there is one
    useEffect(() => {
        (async () => {
            if (slug) {
                let draft = await executeFetch.get(`/retrieve-songs/drafts/${slug}`);
                // console.log('before convert', draft);
                draft = convertDraftFromDB(draft);
                // console.log('after convert', draft);
                setAllSongData(draft);
            }
        })()
    }, [slug])
    // update local storage as song data changes
    // useEffect(() => {
    //     localStorage.drafts = localStorage.drafts || '[]';
    //     const drafts = JSON.parse(localStorage.drafts);
    //     const index = drafts.findIndex(song => song.slug === allSongData.slug)
    //     if(index === -1) /* this song is not in local storage */ {
    //         drafts.push(allSongData);
    //     } else {
    //         drafts[index] = allSongData;
    //     }
    //     localStorage.drafts = JSON.stringify(drafts);
    // }, [allSongData])

    async function handleSubmit(e) {
        e.preventDefault();

        const formattedAllSongData = convertToDBformat(allSongData);

        const formData = new FormData();
        Object.keys(formattedAllSongData).forEach(key => formData.append(key, (allSongData[key])));

        // for (var pair of formData.entries()) {
        //     console.log('entry', 'type', typeof pair[1], pair[0] + ': ' + pair[1]);
        // }

        const status = await executeFetch.post2('/submit-song', formData)
        console.log(status)

        history.push('/admin/song-drafts');
    };

    return (
        <div className={style.container}>
            <form className={style.formContainer} onSubmit={handleSubmit} encType="multipart/form-data">
                <SongNameInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <SlugInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <ArtistNameInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <AlbumInfoInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <ChordsLyricsSegmentsInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <TagsInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <DifficultyInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <RecommendedKeyInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <CapoPlacementInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <TempoInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <BeatsPerLineInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <StrummingPatternInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <CommentsInput allSongData={allSongData} setAllSongData={setAllSongData} />
                <SubmitButtons allSongData={allSongData} setAllSongData={setAllSongData} />
            </form>
            <CurrentSongs />
        </div>
    )
}
