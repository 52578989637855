import React from 'react'
import style from 'css/user/SongDetails.module.css';

export default function SongDetails({ song, songChordPage }) {
    // console.log('tags from details comp', song.tags);

    return (
        <div className={`${style.thumbDetails} ${songChordPage ? style.songChordPage : ''}`}>
            <div className={`${style.difficulty} ${style[song.difficulty]}`}></div>
            <div className={style.detailSingle}><span className={style.thumbTitle}>Artist: </span>{song.artistName}</div>

            {song.recommendedKey ?
                <div className={style.detailSingle}>
                    <span className={style.thumbTitle}>Key: </span>{song.recommendedKey}
                </div>
                : null}

            {song.includedChords ?
                <div className={style.detailSingle}>
                    <div className={style.includedChordsContainer}>
                        <span className={style.thumbTitle}>Included Chords:&nbsp;</span>
                        <div className={style.includedChords}>{JSON.parse(song.includedChords).join(', ')}</div>
                    </div>
                </div>
                : null}

            {song.tags ?
                <div className={style.detailSingle}>
                    <div className={style.tagsContainer}>
                        <span className={style.thumbTitle}>Tags:&nbsp;</span>
                        <div className={style.tags}>{JSON.parse(song.tags).join(', ')}</div>
                    </div>
                </div>
                : null}
        </div>
    )
}
