import React from 'react'
import style from 'css/user/WelcomeSection.module.css';
import { useHistory } from 'react-router';
import hero from 'assets/hero/blue_music2000x1124.webp';

export default function WelcomeSection() {

    // require not working for some reason
    // const hero = require('assets/hero/blue_music2000x1124.webp').default;

    // const hero = require('/build/static/media/blue_music2000x1124.655a877f39c6d379ba96.png').default;
    const history = useHistory();

    return (
        <div className={style.container}>

            {/* trying to get white color for play_arrow, only seems to work for certain other icons
        also need to get centering and sizing down */}

            <img className={style.mainImage} src={hero} alt="" />
            <button className={style.ctaButton} onClick={() => history.push('/all-chords')}>
                <span className={style.ctaText}>See Chords</span>
                {/* <span className={`material-icons-two-tone md-light ${style.playArrowIcon}`}>play_arrow</span> */}
                <svg className={style.playArrowIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FDFDFD"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M10 8.64v6.72L15.27 12z" opacity=".3" /><path d="M8 19l11-7L8 5v14zm2-10.36L15.27 12 10 15.36V8.64z" /></svg>

                <div className={style.border_top} />
                <div className={style.border_top_right}>
                    <div className={`${style.border_ball} ${style.btr_left_ball}`} />
                    <div className={`${style.border_ball} ${style.btr_right_ball}`} />
                </div>
                <div className={style.border_bottom_left}>
                    <div className={`${style.border_ball} ${style.bbl_left_ball}`} />
                    <div className={`${style.border_ball} ${style.bbl_right_ball}`} />
                </div>
            </button>
            <a target="_blank" rel="noreferrer" href="https://drive.google.com/drive/folders/1vfehKY2NSwhFQr5KhAMYfwfF99pxtwF6?usp=sharing" className={style.fullDBbutton}>See Full Database</a>
        </div>
    )
}
