import UserLogin from 'components/user/UserLogin/UserLogin';
import React/* , { useState } */ from 'react'
import style from 'css/admin/Admin.module.css';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
// import Login from './Login';
import NavAdmin from './NavAdmin';
import SongDrafts from './SongDrafts';
import SongPollResults from './SongPollResults';
import SubmitSong from './SubmitSong';
import { Helmet } from 'react-helmet';

export default function LayoutAdmin({ user, setUser }) {

    /* TO ENABLE HOLD STATUS ('h') IN FINISHED FIELD, WE NEED TO UPDATE THE PROCEDURE (insertNewSong) PARAMS ALSO */

    return (
        <>  
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/admin-icons/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/admin-icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/admin-icons/favicon-16x16.png" />
                <link rel="manifest" href="/admin-icons/site.webmanifest" />
                <link rel="shortcut icon" href="/admin-icons/favicon.ico" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="msapplication-config" content="/admin-icons/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>
            {user ?
                <div className={style.main}>
                    <NavAdmin user={user} setUser={setUser} />
                    <Switch>
                        <Route path="/admin" exact>
                            <Dashboard />
                        </Route>
                        <Route path="/admin/submit-song" exact>
                            <SubmitSong user={user} />
                        </Route>
                        <Route path="/admin/submit-song/:slug">
                            <SubmitSong user={user} />
                        </Route>
                        <Route path="/admin/song-drafts">
                            <SongDrafts />
                        </Route>
                        <Route path="/admin/song-poll">
                            <SongPollResults />
                        </Route>
                    </Switch>
                </div>
                : <UserLogin setUser={setUser} />}{/*  <Login setUser={setUser} /> */}
        </>
    )
}
