import React, {useState} from 'react'
import style from 'css/admin/SubmitSong.module.css';

export default function AddSegmentInput({ allSongData, setAllSongData, index }) {
    const { fullChordsLyrics = [{}, {}] } = allSongData;
    const [numberOfSegments, setNumberOfSegments] = useState(1);
    
    function handleNumberChange(e) {
        setNumberOfSegments(e.target.value);
    }

    function addSegments() {
        const newArray = [...fullChordsLyrics];
        for (let i = 0; i < numberOfSegments; i++) {
            newArray.push({});           
        }
        setAllSongData({ ...allSongData, fullChordsLyrics: newArray });
    }
    
    return (
        <div className={style.addSegmentInputContainer}>
            <label>Add new segment(s): </label>
            <input onChange={handleNumberChange} type="number" min="0" value={numberOfSegments} />
            <button type="button" onClick={addSegments}>Go</button>
        </div>
    )
}
