import React, { useState, useEffect } from 'react'
import style from 'css/user/NewChords.module.css';
import NewChordsThumb from './NewChordsThumb';
import executeFetch from 'executeFetch';
import useWindowData from 'useWindowData';
import { useHistory } from 'react-router';

export default function NewChordsSection() {

    const [newSongs, setNewSongs] = useState([]);
    const { device } = useWindowData();
    const history = useHistory();


    useEffect(() => {
        (async () => {
            const newSongs = await executeFetch.get('/retrieve-songs/c/new?limit=8');
            setNewSongs(newSongs);

        })();
    }, [])

    return (
        <div id={style.sectionContainer}>
            <h1>New Chords</h1>

            {/* see comments in css for explanation of outer container */}
            <div className={style.outerContainer}>
                <div id={style.contentContainer}>
                    {newSongs.map((song, i) => (
                        <NewChordsThumb key={i} song={song} />
                    ))}

                    <div className={style.gradient}></div>
                </div>

                {device === 'desktop' ?
                    <button className={`btn1 ${style.viewChordsBtn}`} onClick={() => history.push('/all-chords')}>View All Chords</button>
                    : null
                }
            </div>
        </div>

    )
}
