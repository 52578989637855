import React, { useState, /* useEffect */ } from 'react';
import style from 'css/UserLogin.module.css';
import { BsArrowRight } from "react-icons/bs";

import executeFetch from 'executeFetch'
function UserLogin({ setUser}) {

    const [userExists, setUserExists] = useState(false)
    const [username, setUsername] = useState("")
    const [hasPassword, setHasPassword] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState("")
    const [passwordCreated, setPasswordCreated] = useState(false)

    const findUser = async (username) => {

        const response = await executeFetch.post('http://localhost:8080/user/check-username', { username });

        let parsedJson;
        if (response.ok) {
            parsedJson = await response.json();
            setUserExists(true)
            setHasPassword(parsedJson.password)
            return
        }

        // setError(response.message)
        setError("Your username is not registered. Please contact the site administrator for more details.")
        setHasPassword(false)
        setUserExists(false)
    }

    const verifyPassword = async () => {
        const res = await executeFetch.post('http://localhost:8080/user/login', { username, password })
        if (res.ok) {
            setUser(username)
            return
        }
        setError('Incorrect password.')
    }

    const savePassword = async () => {
        if (password === confirmPassword) {
            await executeFetch.post('http://localhost:8080/user/set-password', { username: username, password: password })
            setPasswordCreated(true)
            setHasPassword(true)
            return
        }
        setError("Passwords don't match.")
    }

    return (
        <div className={style.container}>
            <form className={style.loginForm} onSubmit={(e) => { e.preventDefault(); findUser(username) }}>
                <div className={style.headerRow}>
                    {!userExists && <h2 className={style.header}>Enter your credentials</h2>}
                    {userExists && !hasPassword && <h2 className={style.header}>Please create a password for {username}.</h2>}
                    {hasPassword && <h2 className={style.header}>Welcome back, {username}.</h2>}
                </div>


                {!userExists &&
                    <div className={style.inputContainer}>
                        <input className={style.input} placeholder="Username" onChange={(e) => { setUsername(e.target.value); setError("") }} />
                        <BsArrowRight className={style.nextButton} onClick={() => findUser(username)} />
                    </div>
                }

                {userExists && hasPassword &&
                    <div className={style.inputContainer}>
                        <input className={style.input} autoFocus type="password" placeholder="Password" onKeyDown={(e) => { if (e.key === "Enter") { verifyPassword() } }} onChange={(e) => { setPassword(e.target.value) }} />
                        <BsArrowRight className={style.nextButton} onClick={() => verifyPassword()} />
                    </div>
                }

                {userExists && !hasPassword && <>
                    <div className={style.inputContainer}>
                        <input className={style.input} type="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value); setError("") }} />
                    </div>
                    <div className={style.inputContainer}>
                        <input className={style.input} type="password" placeholder="Confirm Password" onChange={(e) => { setConfirmPassword(e.target.value); setError("") }} />
                    </div>
                    <div className={style.inputContainer}>
                        <button className={style.submitButton} onClick={() => savePassword()}>Submit</button>
                    </div>
                </>}

                {error && <div className={style.errorMessage}>{error}</div> }
                
                {passwordCreated && <div className={style.passwordCreated}>Password created. Please log in.</div>}
            
            </form>
        </div>

    );
}

export default UserLogin;