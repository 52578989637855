import { getFilterText } from 'getFilters';
import React, { useState, /* useEffect, */ useRef } from 'react'
import style from 'css/user/FilterOptions.module.css';
import FilterDropdown from './FilterDropdown';


export default function FilterButton({ filter, options }) {
    const [popupShowing, setPopupShowing] = useState(false);

    const filterText = getFilterText(filter);

    /* the idea here is to not have multiple listeners for each button.
    therefore, listener is only added on click and is removed on outside click.
    ********** there might be duplicate listeners added in the event that the user
    clicks on the button multiple times
    */
    const ref = useRef(null);

    function handleOutsideClick(e) {
        if (ref.current && !ref.current.contains(e.target)) {
            setPopupShowing(false);
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    const handleClick = () => {
        if (!popupShowing) {
            setPopupShowing(true);
            document.addEventListener('click', handleOutsideClick);
        }
    }

    return (
        <button ref={ref} className={style.mainFilterButton} onClick={handleClick}>
            {/* {filter} {filter !== 'Artist' ? `${'  '} ${String.fromCharCode(8595)}` : ''} */}
            {filterText} {String.fromCharCode(8595)}
            {popupShowing ? <FilterDropdown filter={filter} options={options} /> : null}
        </button>
    )
}
