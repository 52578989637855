function convertDraftFromDB(draft) {
    // convert chordsLyrics into proper array
    draft.fullChordsLyrics = convertChordsLyricsToArray(draft.fullChordsLyrics);
    draft.tags = JSON.parse(draft.tags);
    draft.strummingPattern = convertStrummingPatternToArray(draft.strummingPattern);
    return draft;
}

function convertChordsLyricsToArray(fullChordsLyrics) {
    const parsedArrayFromDB = JSON.parse(fullChordsLyrics);
    parsedArrayFromDB.forEach(segment => {
        segment.chordsLyrics = segment.chordsLyrics?.join('\n');
    })
    // console.log('parsed from db', parsedArrayFromDB);
    return parsedArrayFromDB;
}

function convertStrummingPatternToArray(strummingPattern) {
    if (!strummingPattern) return;
    // console.log('st pa', strummingPattern)

    const convertedStrumValuesFromDB = strummingPattern.replaceAll('\u00A0', '').split('')
    // console.log(convertedStrumValuesFromDB);
    return convertedStrumValuesFromDB;

}

module.exports = convertDraftFromDB;