import React from 'react';
import useWindowData from 'useWindowData';
import style from 'css/user/FilterOptions.module.css';
import FilterButton from './FilterButton';
import SortByButton from './SortByButton';
import FilterButtonMobile from './FilterButtonMobile';
import {filterCategories} from '../../../constants';


export default function FilterOptions() {
    const { width } = useWindowData();

    return width < 770 ? (
        <div className={style.mobileContainer}>
            
            <FilterButtonMobile />
            <SortByButton />
            
        </div>
    ) : (
        <div className={style.mainContainer}>
            {filterCategories.map((f, i) => <FilterButton key={i} filter={f} />)}
            <SortByButton />
        </div>
    )
}
