import React from 'react'
import style from 'css/admin/SubmitSong.module.css';


export default function RecommendedKeyInput({ allSongData, setAllSongData }) {
    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <label htmlFor="recommendedKey">Recommended Key:</label>
                    <input
                        className='focusable'
                        name="recommendedKey"
                        max="3"
                        value={allSongData.recommendedKey}
                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })} />
                </div>
            }
        </>
    )
}
