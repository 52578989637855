import generateAlbumImageUrl from "generateAlbumImageUrl";

function convertToDBformat(allSongData) {
    // create url for album image
    const albumImageUrl = generateAlbumImageUrl(allSongData, false);
    allSongData.albumImageUrl = albumImageUrl;

    // format chords lyrics
    const formattedFullChordsLyrics = splitAndTrimLines(allSongData.fullChordsLyrics);
    allSongData.fullChordsLyrics = formattedFullChordsLyrics;

    // this function relies on the chords lyrics being split by line
    const includedChords = extractIncludedChords((allSongData.fullChordsLyrics));
    allSongData.includedChords = includedChords;

    // stringify arrays for form data. Sorry, React diehards
    allSongData.fullChordsLyrics = JSON.stringify(allSongData.fullChordsLyrics || []);
    allSongData.includedChords = JSON.stringify(allSongData.includedChords || []);
    allSongData.tags = JSON.stringify(allSongData.tags || []);

    allSongData.strummingPattern = convertStrummingPatternToString(allSongData.strummingPattern);
    return allSongData;
}

function splitAndTrimLines(fullChordsLyricsArray) {
    if (!fullChordsLyricsArray) return [];

    // return immediately if the segments have already been converted to an array
    if (fullChordsLyricsArray.some(segment => Array.isArray(segment.chordsLyrics) === true)) return fullChordsLyricsArray;

    // else, proceed to convert the segments to arrays
    fullChordsLyricsArray.forEach(segment => {
        // console.log('start', segment.chordsLyrics);
        segment.chordsLyrics = segment.chordsLyrics?.split('\n');
        // console.log('after split', segment.chordsLyrics);
        segment.chordsLyrics?.forEach(line => line.trimEnd());
        // console.log('after trim', segment.chordsLyrics);
    })

    return fullChordsLyricsArray;
}

function extractIncludedChords(fullChordsLyricsArray) {
    if (!fullChordsLyricsArray) return [];

    let includedChords = [];
    for (let i = 0; i < fullChordsLyricsArray.length; i++) {
        for (let j = 0; j < fullChordsLyricsArray[i]?.chordsLyrics?.length; j += 2) {
            // better name for simplicity
            let currentLine = fullChordsLyricsArray[i].chordsLyrics[j];

            // split on spaces (creating new array)
            currentLine = currentLine.split(' ');

            // remove empty string entries from array
            currentLine = currentLine.filter(str => str);

            // concatenate new array onto includedChords array
            includedChords = includedChords.concat(currentLine);
        }
    }

    // remove duplicate values by converting into a set and then converting back into an array
    includedChords = [...new Set(includedChords)];
    // alphabetize values
    includedChords.sort();

    // console.log('included chords are', includedChords);
    return includedChords;
}


/* ****still a bug in the event that a person renames a song (e.g.) Acheinu to Acheinu (the same thing), because the program will still detect the old acheinu slug and create another one acheinu-2. I just don't feel like fixing it now. */
function generateSlug(songName, slugArray) {
    if (!songName) return;

    songName = songName.trim()
    // to start, remove any foreign characters or spaces and replace with -
    let slug = songName.toLowerCase().replace(/\s/g, '-').replace(/[^\w-]/g, '')

    // ensure that if this is a duplicate entry, it gets a number at the end. e.g. instead of another 'meheira', name it 'meheira-2', etc.
    let slugAvailable;
    let num = 2;
    let suffix = '';
    while (!slugAvailable) {
        // If entry with this slug exists and it's not the one for this song (checking id), add suffix
        if (slugArray.includes(slug + suffix) && slug /* prevent generating -2 as a slug */) { // slug is unavailable
            suffix = `-${num++}`;
        } else { // slug is available
            slugAvailable = true;
            slug += suffix;
        }
    }
    // console.log('slug is', slug);
    return slug;
}

function convertStrummingPatternToString(strummingPattern) {
    if (!strummingPattern) return;

    // Notice the hack we are using with u00A0 code to enable easier parsing.

    // convert to a string with spaces
    let formattedStrummingPattern = strummingPattern.map(value => value || ' ').join('\u00A0');
    // console.log('with spaces', formattedStrummingPattern)

    return formattedStrummingPattern;
}

export { convertToDBformat, generateSlug }