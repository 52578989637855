import React from 'react'
// import style from 'css/admin/SubmitSong.module.css';

export default function RemoveSegmentButton({ allSongData, setAllSongData, index }) {
    const {fullChordsLyrics} = allSongData;

    function removeCurrentSegment() {
        const newArray = [...fullChordsLyrics];
        newArray.splice(index, 1);
        setAllSongData({...allSongData, fullChordsLyrics: newArray});
    }

    return (
        <button type="button" onClick={removeCurrentSegment}>Remove this segment</button>
    )
}
