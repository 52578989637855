import { getFilterText } from 'getFilters';
import React, { useState, useRef } from 'react'
import style from 'css/user/FilterDropdownMobile.module.css';
import FilterDropdownMobileSuboptions from './FilterDropdownMobileSuboptions';


export default function FilterDropdownMobileOption({ filter }) {
    const ref = useRef();
    const [subOptionsMounted, setSubOptionsMounted] = useState(false);

    const filterText = getFilterText(filter);

    return (
        <div ref={ref} onClick={(e) => {
            /* Here we want to ensure that the suboptions don't unmount when the grandchildren of the
            container div are clicked. In other words, when the user attempts to select one of the options,
            don't unmount the div. Therefore, we are checking if the e.target is either the parent div or
            one of its immediate children. However, if it's a grandchild, don't unmount the container. */
            if (ref.current === e.target || Array.from(ref.current.children).includes(e.target)) {
                    
                setSubOptionsMounted(!subOptionsMounted)
            }
        }
        }
            className={`${style.dropdownOptionContainer} ${subOptionsMounted ? style.optionActive : ''}`}>
            <div className={style.filterText}>{filterText}</div>
            <div className={style.optionArrow}>&#8250;</div>
            {subOptionsMounted ? <FilterDropdownMobileSuboptions filter={filter} /> : null}
        </div>
    )
}
