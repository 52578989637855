import React, { useState, useEffect } from 'react'
import useWindowData from 'useWindowData';
import FilterOptions from './AllChordsPage/FilterOptions';
// import PageNumbers from './AllChordsPage/PageNumbers';
import SongTable from './AllChordsPage/SongTable'
import SongThumbGroup from './AllChordsPage/SongThumbGroup';
import ToggleDisplay from './AllChordsPage/ToggleDisplay'
import HeroImage from './HeroImage';
import { useDispatch, useSelector } from 'react-redux';
import { initizalize } from 'actions';
import executeFetch from 'executeFetch';
import Helmet from 'react-helmet';

export default function NewChords() {

    // Check out All Chords component which this code is based on
    const [display, setDisplay] = useState(localStorage.allChordsDisplay || 'thumbnails');
    const { width } = useWindowData();
    const dispatch = useDispatch();

    const img = width < 600 ?
        require('assets/hero/all_chords600x329.png').default
        : width < 1440 ?
            require('assets/hero/all_chords1024x300.png').default
            : width < 1600 ?
                require('assets/hero/all_chords1440x300.png').default
                :
                require('assets/hero/all_chords2000x417.png').default;

    useEffect(() => {
        (async () => {
            const songs = await executeFetch.get('/retrieve-songs/c/new?limit=24');
            dispatch(initizalize(songs));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filteredSongs = useSelector(state => state.songsReducer.filteredSongs);
    const originalSongs = useSelector(state => state.songsReducer.songs);

    // If there are filters, use filtered songs. Otherwise, use original songs.
    const displaySongs = filteredSongs.length ? [...filteredSongs] : [...originalSongs];


    return (
        <>
            <Helmet>
                <title>Kumzitz Corner | New Chords</title>
            </Helmet>
            <HeroImage img={img} header='New Chords' />

            <FilterOptions />
            {/* {filteredSongs.length ? <div>Your filter returned {filteredSongs.length} songs.</div> : null} */}
            <ToggleDisplay display={display} setDisplay={setDisplay} />
            {display === 'table' ? <SongTable songs={displaySongs} /> : null}
            {display === 'thumbnails' ? <SongThumbGroup songs={displaySongs} /> : null}
            {/* <PageNumbers /> */}
        </>
    )
}
