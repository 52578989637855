import React, { useState, useRef } from 'react'
import style from 'css/user/FilterOptions.module.css';
import SortByDropdown from './SortByDropdown';

export default function SortByButton() {

    const [sortByDropdownOpen, setSortByDropdownOpen] = useState(false);

    const ref = useRef(null);

    function handleOutsideClick(e) {
        if (ref.current && !ref.current.contains(e.target)) {
            setSortByDropdownOpen(false);
            document.removeEventListener('click', handleOutsideClick);
        }
    }

    const handleClick = () => {
        if (!sortByDropdownOpen) {
            setSortByDropdownOpen(true);
            document.addEventListener('click', handleOutsideClick);
        }
    }

    return (
        <div ref={ref} onClick={handleClick}
            className={`${style.sortByBtn} ${sortByDropdownOpen ? style.sortByActive : ''}`}>
            <span >Sort By</span>
            {sortByDropdownOpen ? <SortByDropdown sortByDropdownOpen={sortByDropdownOpen} setSortByDropdownOpen={setSortByDropdownOpen} /> : null}
        </div>
    )
}
