import React from 'react'
import Helmet from 'react-helmet';

export default function About() {
    return (
        <div>
            <Helmet>
                <title>Kumzitz Corner | About</title>
            </Helmet>
        </div>
    )
}
