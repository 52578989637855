import React, { useState, useEffect } from 'react'
import useWindowData from 'useWindowData';
import FilterOptions from './AllChordsPage/FilterOptions';
import PageNumbers from './AllChordsPage/PageNumbers';
import SongTable from './AllChordsPage/SongTable'
import SongThumbGroup from './AllChordsPage/SongThumbGroup';
import ToggleDisplay from './AllChordsPage/ToggleDisplay'
import HeroImage from './HeroImage';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchResults } from 'actions';
import executeFetch from 'executeFetch';
import { useLocation } from 'react-router';
import Tagline from './SearchResults/Tagline';
import Helmet from 'react-helmet';

// many components are imported from the AllChords folder, as this page is of similar nature

/* 
To do: add a section which will display the search term and number of results. 

We may also want to change the folder name from AllChordsPage to ChordsSelectionPage to include All Chords and Search Results (and maybe Featured/New Chords later).
*/

export default function SearchResults() {
    const { width } = useWindowData();
    const img =
        width < 600 ?
            require('assets/hero/instruments600x329.png').default
            : width < 1440 ?
                require('assets/hero/instruments1024x300.png').default
                : width < 1600 ?
                    require('assets/hero/instruments1440x300.png').default
                    : require('assets/hero/instruments2000x417.png').default;

    const [display, setDisplay] = useState('thumbnails');
    const dispatch = useDispatch();
    const location = useLocation();
    // console.log(location);
    const query = new URLSearchParams(location.search).get('q');

    useEffect(() => {
        (async () => {
            const searchResults = await executeFetch.get(`/search?q=${query}`);
            // console.log('results for', query, searchResults);
            dispatch(setSearchResults(searchResults));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    // To be used once we add filter functionality for SearchResults
    // const filteredSongs = useSelector(state => state.songsReducer.filteredSongs);
    const searchResults = useSelector(state => state.songsReducer.searchResultsSongs);

    const songs = /* filteredSongs.length ? filteredSongs : */ searchResults;


    return (
        <>
            <Helmet>
                <title>Kumzitz Corner | Search Results</title>
            </Helmet>
            <HeroImage img={img} header='Search Results' />

            <Tagline numResults={searchResults.length} searchTerm={query} />

            <FilterOptions />

            <ToggleDisplay display={display} setDisplay={setDisplay} />

            {display === 'table' ? <SongTable songs={songs} /> : null}
            {display === 'thumbnails' ? <SongThumbGroup songs={songs} /> : null}
            <PageNumbers />
        </>
    )
}
