import React from 'react';
import style from 'css/admin/SubmitSong.module.css';


export default function DifficultyInput({ allSongData, setAllSongData }) {
    const difficultyOptions = ['beginner', 'intermediate', 'advanced'];

    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <label>Difficulty:</label>
                    <div className={style.choiceContainer}>
                        {difficultyOptions.map((difficulty, i) => {
                            return (
                                <div className={`flex ${style.choiceSingle}`} key={i}>
                                    <input className='focusable' type="radio" name="difficulty" value={difficulty} onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })}
                                        checked={difficulty === allSongData.difficulty}
                                    />
                                    <label htmlFor="difficulty">{difficulty}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>}
        </>
    )
}
