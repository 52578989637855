import * as m1 from 'assets/artists_display/mobile/1_MBD.png'
import * as m2 from 'assets/artists_display/mobile/2_ShlomoCarlebach.png'
import * as m3 from 'assets/artists_display/mobile/3_SimchaLeiner.png'
import * as m4 from 'assets/artists_display/mobile/4_MordechaiShapiro.png'
import * as m5 from 'assets/artists_display/mobile/5_AbieRotenberg.png'
import * as m6 from 'assets/artists_display/mobile/6_OhadMoskowitz.png'
import * as m7 from 'assets/artists_display/mobile/7_AvrahamFried.png'
import * as m8 from 'assets/artists_display/mobile/8_YaakovShwekey.png'
import * as m9 from 'assets/artists_display/mobile/9_ShloimeGertner.png'
import * as m10 from 'assets/artists_display/mobile/10_BennyFriedman.png'
import * as m11 from 'assets/artists_display/mobile/11_EitanKatz.png'
import * as m12 from 'assets/artists_display/mobile/12_8thDay.png'
import * as m13 from 'assets/artists_display/mobile/13_MesivtaOfWaterbury.png'

import * as t1 from 'assets/artists_display/tablet/1_MBD.png'
import * as t2 from 'assets/artists_display/tablet/2_ShlomoCarlebach.png'
import * as t3 from 'assets/artists_display/tablet/3_MordechaiShapiro.png'
import * as t4 from 'assets/artists_display/tablet/4_SimchaLeiner.png'
import * as t5 from 'assets/artists_display/tablet/5_YaakovShwekey.png'
import * as t6 from 'assets/artists_display/tablet/6_AbieRotenberg.png'
import * as t7 from 'assets/artists_display/tablet/7_OhadMoskowitz.png'
import * as t8 from 'assets/artists_display/tablet/8_AvrahamFried.png'
import * as t9 from 'assets/artists_display/tablet/9_EitanKatz.png'
import * as t10 from 'assets/artists_display/tablet/10_BennyFriedman.png'
import * as t11 from 'assets/artists_display/tablet/11_ShloimeGertner.png'
import * as t12 from 'assets/artists_display/tablet/12_8thDay.png'
import * as t13 from 'assets/artists_display/tablet/13_MesivtaOfWaterbury.png'

import * as d1 from 'assets/artists_display/desktop/1_MBD.png'
import * as d2 from 'assets/artists_display/desktop/2_ShlomoCarlebach.png'
import * as d3 from 'assets/artists_display/desktop/3_MordechaiShapiro.png'
import * as d4 from 'assets/artists_display/desktop/4_AbieRotenberg.png'
import * as d5 from 'assets/artists_display/desktop/5_SimchaLeiner.png'
import * as d6 from 'assets/artists_display/desktop/6_YaakovShwekey.png'
import * as d7 from 'assets/artists_display/desktop/7_OhadMoskowitz.png'
import * as d8 from 'assets/artists_display/desktop/8_AvrahamFried.png'
import * as d9 from 'assets/artists_display/desktop/9_BennyFriedman.png'
import * as d10 from 'assets/artists_display/desktop/10_EitanKatz.png'
import * as d11 from 'assets/artists_display/desktop/11_8thDay.png'
import * as d12 from 'assets/artists_display/desktop/12_MesivtaOfWaterbury.png'
import * as d13 from 'assets/artists_display/desktop/13_ShloimeGertner.png'

const mobileArtistURLs = [m1, m2, m3, m4, m5, m6, m7, m8, m9, m10, m11, m12, m13]
const tabletArtistURLs = [t1, t2, t3, t4, t5, t6, t7, t8, t9, t10, t11, t12, t13]
const desktopArtistURLs = [d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13]


export {mobileArtistURLs, tabletArtistURLs, desktopArtistURLs} ;