import React, { useState, useRef } from 'react'
import style from 'css/user/Nav.module.css';
import { Link, NavLink } from 'react-router-dom';
import logo from 'assets/logo/40.png';
import SearchBar from './Nav/SearchBar';
import useWindowData from 'useWindowData';
import Hamburger from './Nav/Hamburger';
import MobileMenu from './Nav/MobileMenu';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function Nav() {
    const { width } = useWindowData();
    const [mobileMenuShowing, setMobileMenuShowing] = useState(false)
    const [mobileMenuMounted, setMobileMenuMounted] = useState(false)

    const container = useRef();
    useGSAP(() => {
        gsap.fromTo(".banner", {x: '100%'}, {x: '-110%', duration: 20, repeat: -1, ease: 'none', repeatDelay: 0})
        // gsap.to('.banner', {x: '50%'})
    }, { scope: container })

    return width < 800 ?
        /* slight bug - we have onFocus event on nav to ensure that mobile menu
        hides when any part of nav is clicked. onFocus makes that when any element
        inside nav is clicked, the mobile nav should go away.
        this works for user clicking any part of the nav, including using the input.
        however, it does not work for user clicking the search icon.
        One (bad) solution would be to just pass the setMobileMenuMounted function
        to the search bar, and add to the click listener there.
        If we were using Redux, we would just track the mouse click anywhere on the screen
        and close the mobile menu if it was anywhere other than the menu itself.
        */
        <nav id={style.menu} onFocus={() => setMobileMenuMounted(false)}>
            {/* see comments in Nav.module.css for explanation of container1 and container2 */}
            <div className={`${style.container1} ${mobileMenuShowing ? style.container1Active : ''}`}>
                <div id={style.menuLogo}><Link to="/"><img src={logo} alt="" /></Link></div>
                <Hamburger
                    mobileMenuShowing={mobileMenuShowing}
                    setMobileMenuMounted={setMobileMenuMounted}
                    setMobileMenuShowing={setMobileMenuShowing} />
            </div>

            {mobileMenuShowing ?
                <MobileMenu
                    mobileMenuMounted={mobileMenuMounted}
                    setMobileMenuMounted={setMobileMenuMounted}
                    setMobileMenuShowing={setMobileMenuShowing}
                /> : null}

            <div className={style.container2}>
                <SearchBar />
            </div>
        </nav>
        : (
            <nav ref={container} id={style.menu}>
                <div id={style.moving_banner} className='banner'>Hey, everyone, welcome to Kumzitz Corner, formerly The Jewish Chords. Please send feedback and requests to thejewishchords@gmail.com or use our contact form.</div>
                <div id={style.menuLogo}><Link to="/"><img src={logo} alt="" /></Link></div>

                <div id={style.chordsDropdown} className={style.menuItem}><NavLink to="/all-chords">Chords</NavLink></div>
                <SearchBar />
                <div id={style.aboutLink} className={style.menuItem}><NavLink to="/about">About</NavLink></div>
                {/* <div id={style.downloads} className={style.menuItem}><NavLink to="/downloads">Downloads</NavLink></div> | */}
                {/* <div className={style.signInContainer}>
                    <div className={style.userIcon}>
                        <i className="fas fa-user"></i>
                    </div>
                    <NavLink to="/sign-in">Sign In</NavLink>
                </div> */}
            </nav>
        )
}
