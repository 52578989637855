import React from 'react'
import style from 'css/user/ToggleDisplay.module.css';

export default function ToggleDisplay({ display, setDisplay }) {

    // style.active not working inside the ternary for some reason, but working outside of it

    // console.log('STYLE', style, display);
    return (
        <div className={style.toggleDisplayContainer}>
            <i id="thumbnails" title="Grid View"
            className={`fas fa-th ${display === 'thumbnails' ? style.active : ''}`} 
            onClick={() => {
                setDisplay('thumbnails');
                localStorage.allChordsDisplay = 'thumbnails';
            }}></i>
            <i id="table" title="List View"
            className={`fas fa-list-ul ${display === 'table' ? style.active : ''}`} 
            onClick={() => {
                setDisplay('table');
                localStorage.allChordsDisplay = 'table';
            }}></i>

            {/* <p className={style.active}>foo</p> */}

        </div>
    )
}
