import React from 'react'
import style from 'css/user/Footer.module.css';
import logo from 'assets/logo/40.png';
import ContactForm from './ContactForm';
import SubscribeButton from './SubscribeButton';

export default function Footer({ setPopup, setModalShowing }) {
    // ******** should eventually be fixed to the bottom with a margin to offset it
    return (
        <footer>
            <SubscribeButton setPopup={setPopup} setModalShowing={setModalShowing} />
            <img id={style.logo} src={logo} alt="" />
            <p id={style.contact} onClick={() => {
                setPopup(<ContactForm setModalShowing={setModalShowing} />)
                setModalShowing(true);
            }}>Contact Us</p>
            <p id={style.copyright}>&copy; The Jewish Chords 2018-2021</p>
        </footer>
    )
}
