import React from 'react'
import style from 'css/user/SearchResultsTagline.module.css';

export default function Tagline({numResults, searchTerm}) {
    const resultText = numResults === 1 ? 'result' : 'results';
    const text = `${numResults} ${resultText} for "${searchTerm}"`
    
    return (
        <h1 className={style.tagline}>
            {text}
        </h1>
    )
}
