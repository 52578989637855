import React from 'react'
import useWindowData from 'useWindowData';
import style from 'css/user/Table.module.css';
import SongRowTable from './SongTableRow';
import { sortSongs } from 'actions';
import { useDispatch } from 'react-redux';

export default function SongTable({ songs }) {
    const {width} = useWindowData();
    const originalTableColumns = ['name', 'artistName', 'recommendedKey', 'difficulty', 'tags', 'includedChords'];
    const tableColumns = 
    width < 400 ? [...originalTableColumns.slice(0, 2)] : 
    width < 530 ?  [...originalTableColumns.slice(0, 3)] : 
    width < 788 ?  [...originalTableColumns.slice(0, 4)] : 
    width < 900 ?  [...originalTableColumns.slice(0, 5)] :
    originalTableColumns;

    const dispatch = useDispatch();

    return (
        // <div className={style.tableWrapper}>
        <>
        {/* <div>{width}</div> */}

            <table className={style.flTable}>
                <thead>
                    <tr>
                      {tableColumns.map((tc, i) => <th key={i} 
                      onClick={() => dispatch(sortSongs(tc))}>{tc.replace('_', ' ')}</th>)}

                </tr>
                </thead>
                <tbody>
                    {songs.map((s, i) => <SongRowTable key={i} songDetails={s} tableColumns={tableColumns} />)}
                </tbody>
            </table>
            </>
        // </div>
    )
}
