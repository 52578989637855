import React, { useState, useEffect } from 'react'
import style from 'css/admin/SubmitSong.module.css';
import executeFetch from 'executeFetch';

export default function ArtistNameInput({ allSongData, setAllSongData }) {
    const [artists, setArtists] = useState([]);

    // retrieve all artists currently in db
    useEffect(() => {
        (async () => {
            const artists = await executeFetch.get('/search/artists');
            setArtists(artists);
        })();
    }, [])

    return (
        <>
            {allSongData &&
                <div className={style.inputSection}>
                    <input required
                        className='focusable'
                        name="artistName"
                        value={allSongData.artistName}
                        list="artistNames"
                        placeholder='Enter Artist Name'
                        onChange={e => setAllSongData({ ...allSongData, [e.target.name]: e.target.value })} />
                    <datalist id="artistNames">
                        {artists.map((artist, i) => (
                            <option key={i} value={artist}>{artist}</option>
                        ))}
                    </datalist>
                </div>
            }
        </>
    )
}
