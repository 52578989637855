import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import executeFetch from 'executeFetch';
import HeaderSection from './SongChordPage/HeaderSection';
import ChordsLyrics from './SongChordPage/ChordsLyrics';
import Helmet from 'react-helmet';

export default function SongChordPage() {
    const { slug } = useParams();
    // console.log('slug', slug);
    const [song, setSong] = useState();
    // const [img, setImage] = useState();

    useEffect(() => {
        (async () => {
            const song = await executeFetch.get(`/retrieve-songs/s/${slug}`);
            // console.log(song);
            setSong(song); // seems like require() doesn't work inside useEffect for some reason
        })();
    }, [slug])


    return (
        <>
            {song && song.songName ?
            <Helmet>
                <title>{song.songName} | Kumzitz Corner</title>
                <meta name="description" content={`Learn ${song.songName} chords here. Check out our website for our full selection of FREE Jewish music chords for any instrument.`}></meta>
            </Helmet>
            : null}
            <HeaderSection song={song} />

            <ChordsLyrics song={song} />
        </>
    )
}
