export const initizalize = (payload) => {
    return {
        type: 'INITIALIZE_SONGS',
        payload: payload
    }
}

export const sortSongs = (payload) =>{
    return {
        type: 'SORT_SONGS',
        payload: payload
    }
}

export const filterSongs = (payload) => {
    return {
        type: 'FILTER_SONGS',
        payload: payload
    }
}

export const setSearchResults = (payload) =>{
    return {
        type: 'SET_SEARCH_RESULTS',
        payload: payload
    }
}