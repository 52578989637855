import React, { useState } from 'react'
import { useHistory } from 'react-router';
import useWindowData from 'useWindowData';
import style from 'css/user/PopularArtists.module.css';
import { mobileArtistURLs, tabletArtistURLs, desktopArtistURLs } from './artistPics';


export default function PopularArtistsSection() {
    // console.log(mobileArtistURLs);
    const [windowData] = useState(useWindowData());
    const { width: deviceWidth } = windowData;
    const history = useHistory();

    // ******** for some reason there is a big loop here that is slowing us down a lot

    let URLs = deviceWidth < 600 ? mobileArtistURLs : deviceWidth < 1000 ? tabletArtistURLs : desktopArtistURLs;
    // console.log('urls', URLs)
    URLs = URLs.map(module => module.default);
    // console.log('mapped urls', URLs);

    /*
    Remove name from a url. E.g. in the url 'assets/artists_display/desktop/13_ShloimeGertner.png', look for the name (ShloimeGertner) that follows the number + underscore (13_). Look for names that begin with a letter [(]A-Z a-z] or even a number [0-9] such as 8thDay in 'assets/artists_display/desktop/11_8thDay.png'.
    */
    const extractNameRegex = /(?<=[0-9]_)[0-9A-Za-z]+/g;

    /* Find a capital letter that's followed by a lowercase letter. The purpose of this is to exclude consecutive capital letters, as in MBD. */
    const splitNamesRegex = /(?=[A-Z][a-z])/

    /* extract the name from the url string (see above) and ensure that the names are separated by a space */
    function parseURL(string) {
        // console.log(string);
        string = string.match(extractNameRegex)[0]; // extract the name
        // console.log('extracted string', string);
        return string.split(splitNamesRegex).join(' '); // split the names and then join the resulting array with a space
    }

    return (
        <div id={style.sectionContainer}>
            <h1>Popular Artists</h1>
            <div className={style.sizingContainer}>
                <div id={style.imageGridContainer}>

                    {URLs.map((url, i) =>
                        <img
                            key={i}
                            className={`${style.gridPicItem} ${style[`_${i + 1}`]}`}
                            alt={parseURL(url)} title={parseURL(url)}
                            onClick={() => history.push(`/search?q=${parseURL(url)}`)}
                            src={url} />
                    )}

                </div>
            </div>
            <button className={`btn2 ${style.viewArtistsBtn}`}>View All Artists</button>
        </div>
    )
}
